import { loaderAction, orderAction } from "../const";
import { OrderType } from "../Type/order.type";

export interface OrderReducer {
    order: any;
    loader: boolean;
}
const inititalState: OrderReducer = {
    order: null as any,
    loader:false,
};

const orderReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case orderAction.CREATE_ORDER:
            return {
                ...state,
                order: payload,
                loader:false,
            };
            case orderAction.GET_ORDER:
                return {
                    ...state,
                    order: payload,
                    loader:false,
                };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
            
        default:
            return state;
    }
};
export default orderReducer;
