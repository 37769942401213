import React from "react";

const LoaderApiFetch = () => {
  return (
    <>
      <div
        className={`backdrop-blur-[2px] z-[999]  fixed inset-0 flex items-center justify-center h-[100vh]   w-full mx-auto sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px] bg-[hsla(0,0%,5%,.7)] `}
      >
        <span className={`bt-spinner  z-[9999]  rounded-md`}></span>
      </div>
    </>
  );
};

export default LoaderApiFetch;
