import { AnyAction, Dispatch } from "redux"
import api from "."
import { BASE_API_URL } from "../../constant"
import { setVenueServiceLoading, setVenueServiceSucesss } from "../Action/mobile-action/venuServices.action"

export const getVenueService = (slug:string) =>(dispatch:Dispatch)=>{
  dispatch(setVenueServiceLoading(true))
   api.get(BASE_API_URL+`/business_detail/${slug}/service_zone`)
   .then(({data})=>{
       dispatch(setVenueServiceSucesss(data))
   })
   .catch((er)=>console.log(er))
   .finally(()=>dispatch(setVenueServiceLoading(false)))




}
export interface   IcategoryResponseType  {
    "id": string,
    "category_id": string,
    "name": string,
    "status": boolean,
    "start_time": string,
    "end_time": string,
    "image": {path:string,id:string} | null,
    "created_at":string,
    "updated_at":string,
    "zones": {
        "id": string|number,
        "zone": string,
        "slug": string,
        "status": true
    }[]
}