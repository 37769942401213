// export const useGetDeviceTime = () => {
//   const currentDate = new Date();
//   const hours = String(currentDate.getHours()).padStart(2, "0");
//   const minutes = String(currentDate.getMinutes()).padStart(2, "0");
//   const seconds = String(currentDate.getSeconds()).padStart(2, "0");

import { menuData } from "../../pages/mobile-view/home/static-data";

//   return `${hours}:${minutes}:${seconds}`;
// };

export const capitalizeName = (name: string) => {
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const date = new Date();
export const device_time =
  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

export const formatTime = (timeString: any) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
};

export const getCurrentFormattedDate = () => {
  const currentDate = new Date();
  const options: any = { year: "numeric", month: "short", day: "numeric" };
  return currentDate.toLocaleDateString("en-US", options);
};

export const filterMenuDataByZone = (zone: any) => {
  const foundItem = menuData?.find((item: any) => item?.slug === zone);
  const filteredMenuData = foundItem ? [foundItem] : menuData;
  return filteredMenuData;
};

export const isCategoryOrderAvailable = (
  deviceTime: string,
  startTime: string,
  endTime: string
): boolean => {
  const parseTime = (time: string): number[] =>
    time?.split(":")?.map((t) => parseInt(t));
  const [deviceHours, deviceMinutes, deviceSeconds] = parseTime(deviceTime);
  const [startHours, startMinutes, startSeconds] = parseTime(startTime);
  const [endHours, endMinutes, endSeconds] = parseTime(endTime);

  const deviceTotalSeconds =
    deviceHours * 3600 + deviceMinutes * 60 + deviceSeconds;
  const startTotalSeconds =
    startHours * 3600 + startMinutes * 60 + startSeconds;
  const endTotalSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;

  return true;
  // return (
  //   deviceTotalSeconds >= startTotalSeconds &&
  //   deviceTotalSeconds <= endTotalSeconds
  // );
};


export const availableCurrency={
  'npr':"NRs"
}