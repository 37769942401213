import React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  modalClose: () => void;
  bgNormal?: boolean;
  height?: string;
  width?: string;
  position?: string;
  mx?: string;
}

const Modal: React.FC<Props> = ({
  children,
  className,
  modalClose,
  bgNormal,
  position = "bottom",
  mx = "0",
}) => {
  return (
    <React.Fragment>
      <div
        className={`${
          !bgNormal ? "backdrop-blur-sm" : "backdrop-blur-[1px] "
        } ${className} fixed h-[100vh] top-0 left-0 z-50 right-0 bottom-0 overscroll-contain w-full mx-auto sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px] bg-[hsla(0,0%,5%,.7)] `}
        onClick={modalClose}
      />
{/* translate-x-1/2 */}
      <div
        data-aos="fade-up"
        className={`fixed overscroll-contain  bottom-0  sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px] z-50  w-full   ${position}-0 z-[99999] bg-white rounded--md mx-${mx}  shadow overflow-hidden`}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default Modal;
