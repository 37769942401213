import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoCopySharp } from "react-icons/io5";

import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addTableNumnber } from "../../store/Action/mobile-action/add-table-number.action";
import { StateType } from "../../store/Reducer/index.reducer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Modal,
  ParagraphText,
  TextField,
  TitleText,
} from "../../mobile-view-components";
import Button from "../../mobile-view-components/button";
import { HiArrowSmLeft, HiOutlineChevronDown } from "react-icons/hi";
import { notify } from "../../Helpers/Notification";
import { MobileContainerContext } from "../../mobile-view-components/mobile-container";
import { SocialIcon } from "react-social-icons";
import api from "../../store/api";
import { Service } from "../../mobile-view/network";
import { FaRegCopy } from "react-icons/fa";

const TABLE_FORM_VALIDATION = Yup.object().shape({
  tableNumber: Yup.string()
    // .matches(/^[0-9]+$/, "Mus t be a number")
    .required("Table Number is required"),
});

const Banner = ({ children }: { children: any }) => {
  return (
    <div className="relative ">
      <GoBack />
      {children}
    </div>
  );
};

export default React.memo(Banner);
const GoBack = () => {
  const navigate = useNavigate();
  return (
    <div
      className="p-1 absolute left-5 top-5 z-[10] button-bounce bg-white rounded-full cursor-pointer"
      onClick={() => navigate(-1)}
    >
      <HiArrowSmLeft className="h-6 w-6" />
    </div>
  );
};

type ITableNumberSectionPropType = {
  showTableToggler?: boolean;
};

const TableNumberSection = ({
  showTableToggler = true,
}: ITableNumberSectionPropType) => {
  const {
    handleModalClick,
    isTableModalOpen,
    handlers,
    isSocialModalOpen,
    setSocialModalOpen,
    isFeedBackModalOpen,
    setFeedBackModalOpen,
    isWifiModalOpen,
    setWifiModalOpen,
  } = useContext(MobileContainerContext);

  const { socials, slug, capacity, wifi_settings } = useSelector(
    (state: StateType) => state.businessDetail
  );
  const { tableNumber: tableDataNumberReducer } = useSelector(
    (state: StateType) => state.addTableNumberMobile
  );
  const { services, loading } = useSelector(
    (state: StateType) => state.venuService
  );

  const { venuSlug, zone: Zone } = useParams();
  const [query, setQuery] = useSearchParams();
  let zone = query.get("zone");

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [tableNumber, setTableNumber] = useState<any>("");

  useEffect(() => {
    const storedTableNumber = localStorage.getItem("tableNumber");
    if (storedTableNumber) {
      setTableNumber(storedTableNumber);
    }
  }, []);
  // const { services, loading } = useSelector(
  //   (state: StateType) =>
  // );

  useEffect(() => {
    setTableNumber(tableDataNumberReducer || "");
  }, [tableDataNumberReducer]);

  const [isZoneModalOpen, setZoneModalOpen] = useState(false);

  const handleZoneModalClose = () => setZoneModalOpen((prev) => !prev);
  const redirectToCategoryList = ({ slug, id }: any) => {
    navigate(`/category/${venuSlug}?zone=${slug}`);
  };
  const handleCategoryOptionClick = ({ slug, id }: any) => {
    redirectToCategoryList({ slug, id });
    handleZoneModalClose();
  };
  const handleSumbitFeedBack = (
    values: any,
    { setSubmitting, errors }: any
  ) => {
    console.log({ errors });
    if (errors?.phone) {
      notify("error", errors.phone);

      return;
    }
    //make a api call
    Service.post(`feedback/` + venuSlug, values)
      .then(() => {
        setFeedBackModalOpen(false);
        notify("success", "Thank You for your feedback");
      })
      .catch(() => {
        notify("error", "Something Went Worng Please try again later!");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const copyToClipboard = (value: string) => {
    // Copy text to clipboard
    navigator.clipboard
      .writeText(value)
      .then(() => {
        notify("success", "Copied");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  const selectedZone = React.useMemo(() => {
    if (services.length) {
      return services.find((i: any) => i.slug === zone || i.slug === Zone);
    }
  }, [services, zone, Zone]);
  console.log({ isTableModalOpen }, "d");

  useEffect(() => {
    if (isTableModalOpen || isZoneModalOpen|| isWifiModalOpen) {
      window.document.body.style.overflow = "hidden";
    } else window.document.body.style.overflow = "scroll";
  }, [isTableModalOpen, isZoneModalOpen, isWifiModalOpen]);

  return (
    <div className="shadow-sm   py-2">
      {!showTableToggler ? null : (
        <div className="my-0 flex justify-between px-4  items-center   bg-white rounded-xl   ">
          <div
            className="bg-gray-300 flex py-[6px] rounded-lg px-3 space-x-1 cursor-pointer"
            onClick={handleZoneModalClose}
          >
            <ParagraphText className="text-l font-semibold capitalize ">
              {selectedZone?.zone}
            </ParagraphText>
            <HiOutlineChevronDown className="h-6 w-6  " />
          </div>
          <div className="flex   justify-center  items-center space-x-3">
            <div className="">
              {tableNumber ? (
                <div className="flex justify-center items-center space-x-2 ">
                  <ParagraphText
                    onClick={handleModalClick}
                    className="cursor-pointer bg-black text-white text-sm rounded-lg  px-3 py-[8px]  font-bold text-center  "
                  >
                    Change Table
                  </ParagraphText>
                  <ParagraphText className="flex co capitalize font-semibold text-sm">
                    {tableNumber}
                  </ParagraphText>
                </div>
              ) : (
                <ParagraphText
                  onClick={handleModalClick}
                  className="cursor-pointer bg-black text-white text-sm rounded-lg px-3 py-2 font-bold text-center  "
                >
                  Select
                </ParagraphText>
              )}
            </div>
          </div>
        </div>
      )}
      {isTableModalOpen && (
        <Modal modalClose={handleModalClick} bgNormal mx="0">
          <div className="border-b-2 border-gray-300 flex items-center justify-between py-3 px-4 shadow">
            <TitleText className="font-semibold text-lg mt-3 ">
              Where are you seated?
            </TitleText>
            <IoMdClose
              size={20}
              onClick={() => {
                handleModalClick();
                // setIsModalOpen(false);
                // close();
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="px-5 py-2 mt-3">
            <Formik
              initialValues={{ tableNumber: tableNumber }}
              onSubmit={(values, { setSubmitting }) => {
                if (
                  ["undefined", "null", undefined, null].includes(capacity) ||
                  values.tableNumber.toLocaleLowerCase() === "hall" ||
                  Number(values.tableNumber) <= Number(capacity)
                ) {
                  localStorage.setItem("tableNumber", values.tableNumber);
                  dispatch(addTableNumnber(values.tableNumber));
                  setTableNumber(values.tableNumber);
                  handleModalClick();
                  handlers?.redirect();
                  return;
                }
                notify(
                  "error",
                  <span>
                    Invalid Table Number <br></br> Only {capacity}{" "}
                    {capacity > 1 ? "tables" : "table"} Available{" "}
                  </span>
                );
                setSubmitting(false);
                handleModalClick();
              }}
              validationSchema={TABLE_FORM_VALIDATION}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <div className="my-5">
                    <TextField
                      name="tableNumber"
                      label="Table Number"
                      placeholder="Enter a table number"
                      type="text"
                    />
                  </div>
                  <Button
                    isSubmitting={isSubmitting}
                    // isValid={isValid}
                    text="submit"
                    type="submit"
                    variant="primary"
                    className="w-full py-2.5 mb-3"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      {isZoneModalOpen && (
        <Modal modalClose={handleZoneModalClose} bgNormal mx="0">
          <div className="border-b-2 border-gray-300 flex items-center justify-between py-3 px-4 shadow">
            <TitleText className="font-semibold text-lg mt-3 ">
              How do you like to order ?
            </TitleText>
            <IoMdClose
              size={20}
              onClick={handleZoneModalClose}
              className="cursor-pointer"
            />
          </div>
          <div className="px-5 py-2 mt-3 max-h-[340px] overflow-y-scroll">
            {services?.map((item: any) => (
              <div
                className="flex items-center justify-between border-b border-gray-300 last:border-0 py-3"
                key={item?.id}
                onClick={() =>
                  handleCategoryOptionClick({
                    slug: item.slug,
                    id: item.id,
                  })
                }
              >
                <ParagraphText className="capitalize text-base  cursor-pointer">
                  {item?.zone}
                </ParagraphText>
                {item?.icon}
              </div>
            ))}
          </div>
        </Modal>
      )}
      {isSocialModalOpen && (
        <Modal
          modalClose={() => {
            setSocialModalOpen(false);
          }}
          bgNormal
          mx="0"
        >
          <div className="border-b-2 border-gray-300 flex items-center justify-between py-3 px-4 shadow">
            <TitleText className="font-semibold text-lg mt-3 ">
              Social Links
            </TitleText>
            <IoMdClose
              size={20}
              onClick={() => {
                setSocialModalOpen(false);
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="px-5 py-[1px] mt-3 min-h-[60px]   max-h-[340px] overflow-y-scroll">
            {socials?.length ? (
              socials?.map(({ url }: any, index: number) => (
                <a
                  className=" flex items-center gap-2 hover:text-black  text-black   decoration-none justify-start border-b border-gray-300 last:border-0 py-3  "
                  key={index}
                  href={url}
                  target="__blank"
                  onClick={() => setSocialModalOpen(false)}
                >
                  <SocialIcon
                    style={{ width: "30px", height: "30px" }}
                    url={url}
                    target="__blank"
                  />
                  <ParagraphText className="text-base  cursor-pointer">
                    {url}
                  </ParagraphText>
                </a>
              ))
            ) : (
              <h2 className="text-base text-gray-500 text-center">
                No Social Links Available{" "}
              </h2>
            )}
          </div>
        </Modal>
      )}
      {isFeedBackModalOpen && (
        <Modal
          modalClose={() => {
            setFeedBackModalOpen(false);
          }}
          bgNormal
          mx="0"
        >
          <div className="border-b-2 border-gray-300 flex items-center justify-between py-3 px-4 shadow">
            <TitleText className="font-semibold text-lg mt-3 ">
              Send Your Feedbacks
            </TitleText>
            <IoMdClose
              size={20}
              onClick={() => {
                setFeedBackModalOpen(false);
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="px-5 py-[1px] mt-3 min-h-[60px]   max-h-[340px] overflow-y-scroll">
            <Formik
              initialValues={{ message: "", name: "", phone: "" }}
              onSubmit={handleSumbitFeedBack}
              validationSchema={Yup.object().shape({
                phone: Yup.string()
                  .matches(/^\d+$/, "Invalid phone number")
                  .required("Phone is required")
                  .min(10, "Invalid phone number")
                  .max(10, "Invalid phone number"),
              })}
            >
              {({ isSubmitting, values, isValid, handleChange, errors }) => (
                <Form>
                  <div className="my-3 font-semibold">
                    <textarea
                      className="w-full h-full p-3  bg-slate-100 rounded-lg font-normal "
                      style={{ resize: "none" }}
                      required
                      name="message"
                      onChange={handleChange}
                      placeholder="Type feedback."
                    >
                      {values.message}
                    </textarea>
                  </div>
                  <div className="my-5 font-semibold">
                    Full Name
                    <input
                      type="text"
                      placeholder="Enter full name."
                      className="w-full h-full p-3 bg-slate-100 rounded-lg font-normal "
                      required
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </div>
                  <div className="my-5 font-semibold">
                    Contact
                    <input
                      type="text"
                      placeholder="Enter phone no."
                      className="w-full h-full p-3  bg-slate-100 rounded-lg font-normal "
                      required
                      name="phone"
                      onChange={handleChange}
                      value={values.phone}
                    />
                    <span className="text-red-500">{errors.phone}</span>
                  </div>
                  <Button
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isDisabled={!isValid}
                    text="submit"
                    type="submit"
                    variant="primary"
                    className="w-full py-2.5 mb-3"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      {isWifiModalOpen && (
        <Modal
          modalClose={() => {
            setWifiModalOpen(false);
          }}
          bgNormal
          mx="0"
        >
          <div className="border-b-2 border-gray-300 flex items-center justify-between py-3 px-4 shadow">
            <TitleText className="font-semibold text-lg mt-3 items-center ">
              Connect to Wi-Fi
            </TitleText>
            <IoMdClose
              size={20}
              onClick={() => {
                setWifiModalOpen(false);
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="px-10  py-[5px] mt-3 min-h-[60px]  max-h-[340px] overflow-y-scroll">
            <div className="info pb-3  justify-start items-center ">
              {/* <div className="tiitles w-full  text-[16px] my-2 font-semibold   flex justify-between  items-center  ">
                <div className="ssid">SSID</div>
                <div className="password">Password</div>
                <div className="copy">Action</div>
              </div> */}
              {!wifi_settings ? (
                <h2>No wifi Available Right Now</h2>
              ) : (
                (wifi_settings ?? []).map((i: any, index: number) => {
                  return (
                    <div className="name   my-2     ">
                        <div className="value text-[14px]"><span className=" text-[14px] font-semibold">SSID:</span>   {i.name} </div>
                        <div className=" flex justify-between items-center ">
                        <div className="value text-[14px]"><span className=" text-[14px] font-semibold">Password:</span>  {i.password} </div>
                          <div
                            className="cursor-pointer "
                            onClick={() => copyToClipboard(i.password)}
                          >
                            <FaRegCopy className="h-4 w-4 button-bounce mt-1" />
                          </div>{" "}
                        </div>
                      
                        <div className="line h-[1px] w-full my-2 bg-zinc-300"></div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export { TableNumberSection };
