import React from "react";
import { HiFire, HiOutlinePlusSm } from "react-icons/hi";
import TitleText from "../../common/TitleText";
import ParagraphText from "../../common/ParagraphText";
import { availableCurrency } from "../../_helpers";
import { Carousel } from "antd";

export const CardImageGallery = ({ images }: any) => {
  return (
    <Carousel
      autoplay
      dots={true}
      dotPosition="top"
      className="relative h-32 w-[9rem] "
    >
      { images?.length ? images.map(({ path }: any, index: number) => {
        return (
          <div key={index} className="relative h-32 w-44  ">
            <img
              loading="lazy"
              className="h-full w-full object-cover absolute rounded-2xl"
              src={path ? `${path}` : `/Images/menu.png`}
            />
          </div>
        );
      }) : <div  className="relative h-32 w-44  ">
      <img
        loading="lazy"
        className="h-full w-full object-cover absolute rounded-2xl"
        src={`/Images/menu.png`}
      />
    </div>}
    </Carousel>
  );
};
const ProductCard = ({ item, ...props }: any) => {
  const handleAddClick = () => {
    props.setIsModalOpen((prev: boolean) => !prev);
    props.setSingleProductDetails(item);
  };
  console.log({ item })

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => (item?.in_stock ? handleAddClick() : null)}
      >
        <div className="flex gap-2 items-center">
          {item?.is_popular && (
            <div className="p-1 bg-primary-yellow w-fit rounded-full ">
              {item?.is_popular ? (
                <HiFire className="h-6 w-6 text-white" />
              ) : null}
            </div>
          )}
          {item?.today_special && (
            <div className="w-fit flex justify-center items-center	bg-gray-300 rounded-xl py-2 px-3">
              {item?.today_special ? (
                <span className="text-black text-xs	 text-center font-semibold font-AvenirLTLight">
                  Today's Special
                </span>
              ) : null}
            </div>
          )}
        </div>

        <div className="flex" id={`${item?.id}`}>
          {/* Popular icon */}

          <div className="flex flex-col pr-4 mt-2 justify-between w-full">
            <div>
              <TitleText className="text-lg font-semibold">
                {item?.name}
              </TitleText>
              <ParagraphText className="orderly-three-line text-gray-500 mt-1 overflow-hidden">
                {item?.description}
              </ParagraphText>
            </div>

            <div className="flex space-x-4 text-gray-500 items-center">
              <ParagraphText className="text-yellow-600">
                {availableCurrency["npr"]}. {item?.price}
              </ParagraphText>
              <div className="flex space-x-2">
                {item?.dietaries?.length > 0 ||
                  item?.dietaries.map((item: any, index: number) => {
                    return (
                      <>
                        <div key={index}>
                          <ParagraphText className="bg-gray-200 text-sm py-1 px-2 rounded-2xl">
                            {item}
                          </ParagraphText>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {item?.in_stock ? (
            <div className="relative">
              <CardImageGallery images={item?.images} />
              <div className="absolute -bottom-4 left-[24%] z-10 py-[5px] pl-3 cursor-pointer pr-4 bg-white border-2 border-yellow-600 rounded-lg  ">
                <div className="flex items-center justify-center ">
                  <HiOutlinePlusSm className="h-4 w-4  text-yellow-600" />
                  <ParagraphText className="text-yellow-600  ">
                    Add{" "}
                  </ParagraphText>
                </div>
              </div>
            </div>
          ) : (
            <div className="cursor-pointer background-image-category relative z-[1]   h-32 w-44 overflow-hidden rounded bg-surface-subtle bg-cover">
              <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-black bg-opacity-60">
                <ParagraphText className=" text-center text-white my-label-md">
                  Sold
                </ParagraphText>
              </div>
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ProductCard;
