
export const AppLoader = ({isLoading,children}:{isLoading:boolean,children:any}) =>{

    if(isLoading){
      return  <div className="relative">
      {/* <Skeleton width={"100%"} height={"100svh"} /> */}
      <div className="bg-spinner-loader w-full h-screen"></div>
      <img
        src="/Images/loading.png"
        alt="loader"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-15 max-w-48 object-contain rounded-md "
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-20">
        <div className="loader-container">
          <div className="bt-spinner ease-linear rounded-full border-8  border-t-8 animate-spin-custom h-48 w-48"></div>
        </div>
      </div>
    </div>
    }
  
    return  children
  
  }