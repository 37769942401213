import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "./store/store";
import App from "./App";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        <Provider store={store}>
        <App />
        </Provider>
    // </I18nextProvider>
);
