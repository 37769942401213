export interface mobileAddToTableNumber {
  tableNumber: string | number;
}

const initialLocalStorageValue = localStorage.getItem("tableNumber");
const initialData: mobileAddToTableNumber = {
  tableNumber: initialLocalStorageValue ? initialLocalStorageValue : "",
};
const mobileAddToTableNumberReducer = (state = initialData, action: any) => {
  switch (action.type) {
    case "ADD_TO_TABLE_NUMBER":
      return {
        ...state,
        tableNumber: action.payload,
      };

    default:
      return state;
  }
};
export default mobileAddToTableNumberReducer;
