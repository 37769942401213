import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { Service } from "../../mobile-view/network";
import { StateType } from "../../store/Reducer/index.reducer";
import * as Yup from "yup";
import { TableNumberSection } from "../../components/Banner";
import Footer from "../Footer";

interface IProps {
  className?: string;
  handleModalClick?: any;
  isTableModelOpen?: boolean;
  redirect?: () => void;
}
interface IMobileContainerContext {
  handleModalClick: () => void;
  isTableModalOpen: boolean;
  setHandlers: any;
  handlers: any;
  isProfileLoading:boolean
  isSocialModalOpen:boolean,
  isFeedBackModalOpen:boolean,
  isWifiModalOpen:boolean
  setSocialModalOpen:React.Dispatch<React.SetStateAction<boolean>>
  setFeedBackModalOpen:React.Dispatch<React.SetStateAction<boolean>>
  setWifiModalOpen:React.Dispatch<React.SetStateAction<boolean>>

}

export const MobileContainerContext =
  React.createContext<IMobileContainerContext>({
    handleModalClick: () => {},
    isTableModalOpen: false,
    setHandlers: null,
    handlers: null,
    isProfileLoading:true,
    isSocialModalOpen:false,
    isFeedBackModalOpen:false,
    isWifiModalOpen:false,
    setSocialModalOpen:()=>{},
    setFeedBackModalOpen:()=>{},
    setWifiModalOpen:()=>{},

  });

const MobileContainer: React.FC<IProps> = ({ className, redirect }) => {
  const [handlers, setHandlers] = useState({});
  const { venuSlug } = useParams();
  const [query,setSearchParams] = useSearchParams()

  const business = useSelector((state: StateType) => state.businessDetail);

  const [isProfileLoading,setIsProfileLoading] = useState(true)
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [isSocialModalOpen, setSocialModalOpen] = useState(false);
  const [isFeedBackModalOpen, setFeedBackModalOpen] = useState(false);
  const [isWifiModalOpen, setWifiModalOpen] = useState(false);


  const handleModalClick = () => {
    setIsTableModalOpen((prev) => !prev);
  };
   
  useEffect(()=>{
    if(query.get('qr')==='true'){
      localStorage.clear()
      dispatch({type:"ADD_TO_TABLE_NUMBER",payload:''})
      setSearchParams({})
    }
     
  },[query])



  useEffect(()=>{
    if(isTableModalOpen || isSocialModalOpen){
      window.document.body.style.overflow='hidden'
    }
    else window.document.body.style.overflow='scroll'

  },[isTableModalOpen,isSocialModalOpen])

  useEffect(()=>{
    if(isFeedBackModalOpen || isFeedBackModalOpen){
      window.document.body.style.overflow='hidden'
    }
    else window.document.body.style.overflow='scroll'

  },[isFeedBackModalOpen,isFeedBackModalOpen])



  useEffect(()=>{
    if(isWifiModalOpen || isWifiModalOpen){
      window.document.body.style.overflow='hidden'
    }
    else window.document.body.style.overflow='scroll'

  },[isWifiModalOpen,isWifiModalOpen])
  

  const dispatch = useDispatch();
  useEffect(() => {
    if (venuSlug && !Object.keys(business).length) {
      setIsProfileLoading(true)
      Service.get("/business_detail/" + venuSlug)
        .then((res) => {
          dispatch({ type: "FETCH_PROFILE", payload: res.data.data });
        })
        .catch(() => {})
        .finally(() => {
      setIsProfileLoading(false)

        });
    }

   
    
  }, [venuSlug]);
  return (
    <div
      className={`${className} w-full mx-auto sm:w-[480px]  md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px] `}
      style={{ minHeight: "100svh" }}
    >
      <MobileContainerContext.Provider
        value={{ handleModalClick, isTableModalOpen, handlers, setHandlers ,isProfileLoading,isSocialModalOpen,setSocialModalOpen,isFeedBackModalOpen,setFeedBackModalOpen,isWifiModalOpen,setWifiModalOpen}}
      >
        <Outlet />
        <TableNumberSection showTableToggler={false} />
      </MobileContainerContext.Provider>
    </div>
  );
};

export default React.memo(MobileContainer);
