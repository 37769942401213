import { IoMdBook } from "react-icons/io";
import { CiStar } from "react-icons/ci";
import { BsGlobe2 } from "react-icons/bs";

export const menuData = [
  {
    id: 0,
    title: "Dine In",
    slug: "dine-in",
    icon: <IoMdBook size={25} color="#868686" />,
    isModal: true,
  },
  ,
  {
    id: 1,
    title: "order room service",
    slug: "in-room",
    icon: <IoMdBook size={25} color="#868686" />,
    isModal: true,
  },
  {
    id: 2,
    title: "Guest Request",
    slug: "guest-request",
    icon: <IoMdBook size={25} color="#868686" />,
    isModal: true,
  },
];
  {/* {subMenuData?.map((item: any) => (
            <div
              className="flex items-center justify-between border-b border-gray-300 py-3"
              key={item?.id}
              onClick={() => {
                handleNavigation(item?.slug);
              }}
            >
              <TitleText className="capitalize cursor-pointer">
                {item?.title}
              </TitleText>
              {item?.icon}
            </div>
          ))} */}

export const subMenuData = [
  
  // {
  //   id: 1,
  //   title: "Feedback",
  //   slug: "",
  //   icon: <CiStar size={25} color="#868686" />,
  //   isModal: false,
  // },
  {
    id: 2,
    title: "Social Media",
    slug: "",
    icon: <BsGlobe2 size={25} color="#868686" />,
    isModal: false,
  },
];

export const drawerData = [
 
  {
    id: 1,
    title: "Leave a review",
    path: "/review",
  },
  {
    id: 2,
    title: "Learn more about Orderly",
    target:'__blank',
    path: "https://orderlyinn.com/",
  },
];
