import { AppLoader } from "../../../components/Loader/app.loader";
import { useState } from "react";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import { TitleText } from "../../../mobile-view-components";
import { IoMdClose } from "react-icons/io";
import Button from "../../../mobile-view-components/button";
import * as Yup from "yup";
import { notify } from "../../../Helpers/Notification";
import { Service } from "../../../mobile-view/network";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StateType } from "../../../store/Reducer/index.reducer";
import { useSelector } from "react-redux";



export default function FeebackPage(){
    const [searchParams,setSearchParams] = useSearchParams()




   
    const navigation = useNavigate()
    const handleSumbitFeedBack = (
        values: any,
        { setSubmitting, errors }: any
      ) => {
        if (errors?.phone) {
          notify("error", errors.phone);
          return;
        }
        //make a api call
        Service.post(`feedback/` +searchParams.get("venue"), values)
          .then(() => {
            navigation(`/${searchParams.get("venue")}`)
            notify("success", "Thank You for feedback");
          })
          .catch(() => {
            notify("error", "Something Went Worng Please try again later!");
          })
          .finally(() => {
            setSubmitting(false);
          });
      };
    return <AppLoader isLoading={false}>
        
    
    <div className="border-b-2 border-gray-300 flex items-center justify-between py-3 px-4 pt-10 shadow">
            <TitleText className="font-semibold text-lg  ">
              Send Your Reviews
            </TitleText>
            <IoMdClose
              size={20}
              onClick={() => {
                navigation(`/${searchParams.get("venue")}`)
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="px-5 py-[1px] mt-3 min-h-[60px]   max-h-[340px] overflow-y-scroll">
            <Formik
              initialValues={{ message: "", name: "", phone: "" }}
              onSubmit={handleSumbitFeedBack}
              validationSchema={Yup.object().shape({
                phone: Yup.string()
                  .matches(/^\d+$/, "Invalid phone number")
                  .required("Phone is required")
                  .min(10, "Invalid phone number")
                  .max(10, "Invalid phone number"),
              })}
            >
              {({ isSubmitting, values, isValid,handleSubmit, handleChange, errors }) => (
                <Form>
                  <div className="my-3 font-semibold">
                    <textarea
                      className="w-full h-full p-3  bg-slate-100 rounded-lg font-normal "
                      style={{ resize: "none" }}
                      required
                      name="message"
                      onChange={handleChange}
                      placeholder="Your Review."
                    >
                      {values.message}
                    </textarea>
                  </div>
                  <div className="my-5 font-semibold">
                    Full Name
                    <input
                      type="text"
                      placeholder="Type full name."
                      className="w-full h-full p-3 bg-slate-100 rounded-lg font-normal "
                      required
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </div>
                  <div className="my-5 font-semibold">
                    Contact
                    <input
                      type="text"
                      placeholder="Type phone no."
                      className="w-full h-full p-3  bg-slate-100 rounded-lg font-normal "
                      required
                      name="phone"
                      onChange={handleChange}
                      value={values.phone}
                    />
                    <span className="text-red-500">{errors.phone}</span>
                  </div>
                  <Button
                  onClick={handleSubmit}
                    isSubmitting={isSubmitting}
                    // isValid={isValid}
                    isDisabled={!isValid}
                    text="submit"
                    type="submit"
                    variant="primary"
                    className="w-full py-2.5 mb-3"
                  />
                </Form>
              )}
            </Formik>
          </div>
       
    </AppLoader>
}

