import React, { useEffect, useState } from "react";

import BannerImage from "../BannerImage/BannerImage";
import {
  HiChevronDown,
  HiOutlineMinusSm,
  HiOutlinePlusSm,
} from "react-icons/hi";
import Button from "../button";

import LoaderApiFetch from "../loader/LoaderApiFetch";
import {
  availableCurrency,
  device_time,
  isCategoryOrderAvailable,
} from "../_helpers";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { useNavigate } from "react-router";
import { Carousel, Collapse, Divider, Tag, message } from "antd";
import { toast } from "react-toastify";
import { notify } from "../../Helpers/Notification";

const ProductDetailsModal = ({
  item,
  isModalOpen,
  setIsModalOpen,
  startTime,
  endTime,
  addToCart,
}: any) => {
  const { loader, cart } = useSelector((state: StateType) => state.cart as any);

  const [quantity, setQuantity] = useState(1);
  const [selectedAddons, setAddons] = useState<
    {
      addon_id: string;
      quantity: string;
    }[]
  >([]);

  const [selectedVariants, SetselectedVariants] = useState<
    { variant_id: string; quantity: string | number }[]
  >([]);

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  useEffect(() => {
    if (cart?.items) {
      let currentItem = cart?.items?.find(
        (cartItem: any) => cartItem?.menu_id == item.id
      );
      if (currentItem) {
        setAddons(
          currentItem?.addons?.map(({ quantity, addon_id, slug, id }: any) => ({
            id: addon_id,
            slug,
            quantity: Number(Number(quantity).toFixed(0)),
          })) ?? []
        );
        setQuantity(Number(Number(currentItem?.quantity).toFixed(0)));
        // set varaints
        console.log(currentItem);
      }
    }
  }, [cart]);

  useEffect(() => {
    if (loader) handleCloseModal();
  }, [loader]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSetAddons = (
    e: React.ChangeEvent<HTMLInputElement>,
    addon_id: string,
    price: string,
    slug: string,
    quantity: string | number,
    id: string
  ) => {
    const { name, checked } = e.target;
    if (checked) {
      setAddons((prev: any) => {
        return [
          ...prev,
          {
            price,
            slug,
            quantity,
            id,
            addon_id,
          },
        ];
      });
    } else {
      setAddons((prev: any) => [...prev.filter((i: any) => i.slug !== slug)]);
    }
  };
  const handleVaraint = (
    e: React.ChangeEvent<HTMLInputElement>,
    price: string,
    quantity: string | number,
    id: string
  ) => {
    const { name, checked } = e.target;
    if (checked) {
      SetselectedVariants((prev: any) => {
        return [
          ...prev,
          {
            price,
            quantity,
            variant_id: id,
          },
        ];
      });
    } else {
      SetselectedVariants((prev: any) => [
        ...prev.filter((i: any) => i.variant_id !== id),
      ]);
    }
  };

  const isCategoryAvailable = isCategoryOrderAvailable(
    device_time,
    startTime,
    endTime
  );

  const handleIncreaseQuantityAddons = (slug: string, dec = false) => {
    setAddons((prev: any[]) => {
      let indexofItem = prev.findIndex((addon, i) => {
        return addon?.slug === slug;
      });
      let quantity = Number(prev[indexofItem]?.quantity ?? 0);

      if (dec && quantity > 1) {
        quantity -= 1;
      }
      if (!dec) {
        quantity += 1;
      }

      prev[indexofItem] = {
        ...prev[indexofItem],
        quantity,
      };
      return [...prev];
    });
  };
  const handleIncreaseQuantityVariants = (id: string, dec = false) => {
    SetselectedVariants((prev: any[]) => {
      let indexofItem = prev.findIndex((variant, i) => {
        return variant?.variant_id === id;
      });

      let quantity = prev[indexofItem]?.quantity ?? 0;

      if (dec && quantity > 1) {
        quantity -= 1;
      }
      if (!dec) {
        quantity += 1;
      }

      prev[indexofItem] = {
        ...prev[indexofItem],
        quantity,
      };
      return [...prev];
    });
  };
  const VariantSection = () => {
    return (
      <React.Fragment>
        <ul className="max-h-[13rem] lg:max-h-[18rem] overscroll-none no-scrollbar overflow-y-scroll">
          {item?.varients.map(({ name, price, id }: any, indx: number) => {
            const isSelected = selectedVariants.find(
              ({ variant_id }, index: number) => variant_id === id
            );
            return (
              <li
                key={indx}
                className="flex items-center gap-1 mt-2  justify-start"
              >
                <label
                  htmlFor={"label-" + id}
                  style={{ width: "80%" }}
                  className="flex flex-0  items-center gap-2"
                >
                  <input
                    type="checkbox"
                    name={name}
                    onChange={(e) => handleVaraint(e, price, 1, id)}
                    checked={!!Boolean(isSelected)}
                    className={`appearance-none 
flex  cursor-pointer pl-8 select-none text-slate-400 
left-0 h-4 w-4 
items-center justify-center rounded-full  border 
border-[#FFAA1E] bg-white  transition-[background-color] 
duration-300 ease-in before:content-['']
checked:border-white-800 
checked:bg-white-200 checked:text-white-800 
checked:content-tick-icon checked:font-bold 
checked:transition-[background-color] checked:duration-300 checked:ease-in `}
                    id={"label-" + id}
                  />
                  <span
                    style={{
                      width: "65%",
                      textAlign: "start",
                    }}
                  >
                    {name.charAt(0).toUpperCase() + name?.slice(1)}
                  </span>

                  <span
                    style={{ width: "30%" }}
                    className="text-small justify-center"
                  >
                    {availableCurrency.npr} {Number(price).toFixed(0)}
                  </span>
                </label>
                <div style={{ width: "20%" }}>
                  {isSelected ? (
                    <QuantityToggler
                      handleDecreaseQuantity={() => {
                        handleIncreaseQuantityVariants(id, true);
                      }}
                      handleIncreaseQuantity={() => {
                        handleIncreaseQuantityVariants(id);
                      }}
                      quantity={isSelected?.quantity as any}
                    />
                  ) : (
                    isSelected
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  };
  const AddonsSection = () =>
    item?.addons?.length ? (
      <React.Fragment>
        {/* <h2 className='text-xl font-bold mb-2'>{'Addons'}</h2> */}
        <div className=" overscroll-none no-scrollbar overflow-y-scroll">
          {item?.addons?.map(({ name, addons }: any, index: number) => (
            <ul key={index}>
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {name.charAt(0).toUpperCase() + name?.slice(1)}
              </span>

              {addons?.map(
                ({ name, price, id, addon_id, slug }: any, index: number) => {
                  let selectItems = selectedAddons?.find(
                    (i: any) => i?.slug == slug
                  );
                  return Addon({
                    setAddons,
                    handleSetAddons,
                    id,
                    price,
                    addon_id,
                    name,
                    selectedAddons,
                    slug,
                    quantity: selectItems?.quantity,
                    isSelected: !!selectItems,
                    handleIncreaseQuantityAddons,
                  });
                }
              )}
            </ul>
          ))}
        </div>
      </React.Fragment>
    ) : (
      <div className="max-h-[13rem]"></div>
    );
  const [activeKey, setActiveTab] = useState<any>(1);
  return (
    <>
      <div
        data-aos="fade-up"
        className={`relative ${isModalOpen}? "an‌​imate-slide-y-in":"an‌​imate-slide-y-out`}
      >
        <div className=" h-screen overflow-scroll pb-[58px] no-scrollbar">
          <div className=" h-[14rem] relative">
            {item?.images?.length ? (
              <Carousel className="p-px" autoplay>
                {item?.images?.map(({ path }: any, index: number) => {
                  return (
                    <BannerImage key={index} isProdut={true} imageUrl={path} className="h-64" />
                  );
                })}
              </Carousel>
            ) : (
              <img
              loading="lazy"
              className="h-full w-full object-cover absolute rounded-2xl"
              src="/Images/menu.png"
            />
            )}
            <HiChevronDown
              // onClick={() => setIsModalOpen(false)}

              onClick={() => handleCloseModal()}
              className="absolute right-10 top-5 z-10 h-8  pt-0.5   w-8 rounded-full bg-white cursor-pointer"
            />
          </div>
          <div className="mt-12 mx-4">
            <div className="flex justify-between mt-10 ">
              <h1 className="text-xl font-bold ">
                {item?.name?.charAt(0).toUpperCase() + item?.name?.slice(1)}
              </h1>
              <h1 className="text-lg font-semibold">
                {availableCurrency["npr"]} {item?.price}
              </h1>
            </div>

            {/* Details */}
            <p> {item?.description}</p>

            {/* Quantity */}
            {!item?.varients?.length ? (
              <div className="flex justify-between items-center">
                <h2 className="text-base font-bold">Quantity</h2>
                <QuantityToggler
                  handleDecreaseQuantity={handleDecreaseQuantity}
                  handleIncreaseQuantity={handleIncreaseQuantity}
                  quantity={quantity}
                />
              </div>
            ) : null}
            <Collapse
              destroyInactivePanel
              className="w-full h-full"
              activeKey={activeKey}
              onChange={(key) => {
                key?.length > 1
                  ? setActiveTab(key[1] as string)
                  : setActiveTab(key[0]);
              }}
              ghost
            >
              {item?.varients?.length ? (
                <Collapse.Panel
                  className="antdCustomeCollapseCss"
                  header={
                    <>
                      <span> Variants </span>
                      <Tag className="ml-[5rem] h-[1rem]" color="default">
                        Required
                      </Tag>
                    </>
                  }
                  key="1"
                >
                  {VariantSection()}
                </Collapse.Panel>
              ) : null}
              {item.addons?.length && (
                <Collapse.Panel
                  className="antdCustomeCollapseCss"
                  header={"Addons"}
                  key="2"
                >
                  {AddonsSection()}
                </Collapse.Panel>
              )}
            </Collapse>
          </div>
        </div>

        {/* Loader when api call is loading             */}
        <div>{loader && <LoaderApiFetch />}</div>
      </div>
      <div
        // style={{transform: 'translate(-3%,10px)'}}
        // className='w-full fixed bottom-[1%] flex justify-center items-center '
        className="fixed  bottom-0 flex justify-center items-center transform left-1/2  -translate-x-1/2  
               z-50  w-full  sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px]  ]"
      >
        <Button
          isValid={true}
          text={isCategoryAvailable ? "Add to Cart" : " Not Available"}
          type="submit"
          onClick={() => {
            if (item?.varients?.length && !selectedVariants.length) {
              notify("error", "Variant is required !");
              return;
            }
            if (isCategoryAvailable) {
              addToCart({
                menu_id: item.id,
                quantity,
                addons: selectedAddons,
                variants: selectedVariants,
              });
            }
          }}
          variant="primary"
          className="w-[95%] py-2.5 mb-3 px-4"
          isDisabled={isCategoryAvailable ? false : true}
        />
      </div>
    </>
  );
};

export default React.memo(ProductDetailsModal);

const Addon = ({
  handleIncreaseQuantityAddons,
  isSelected,
  handleSetAddons,
  id,
  price,
  slug,
  name,
  addon_id,
  quantity,
}: any) => {
  return (
    <li className="flex items-center gap-1 mt-2  justify-start">
      <label
        htmlFor={"label-" + id}
        style={{ width: "80%" }}
        className="flex flex-0  items-center gap-2"
      >
        <input
          type="checkbox"
          name={slug}
          onChange={(e) => handleSetAddons(e, addon_id, price, slug, 1, id)}
          checked={isSelected}
          className={`appearance-none 
flex  cursor-pointer pl-8 select-none text-slate-400 
left-0  h-4 w-4 
items-center justify-center rounded-full  border 
border-[#FFAA1E] bg-white  transition-[background-color] 
duration-300 ease-in before:content-['']
checked:border-white-800 
checked:bg-white-200 checked:text-white-800 
checked:content-tick-icon checked:font-bold 
checked:transition-[background-color] checked:duration-300 checked:ease-in `}
          id={"label-" + id}
        />
        <span
          style={{
            width: "65%",
            textAlign: "start",
          }}
        >
          {name.charAt(0).toUpperCase() + name?.slice(1)}
        </span>

        <span style={{ width: "30%" }} className="text-small justify-center">
          {availableCurrency.npr} {Number(price).toFixed(0)}
        </span>
      </label>
      <div style={{ width: "20%" }}>
        {isSelected ? (
          <QuantityToggler
            handleDecreaseQuantity={() => {
              handleIncreaseQuantityAddons(slug, true);
            }}
            handleIncreaseQuantity={() => {
              handleIncreaseQuantityAddons(slug);
            }}
            quantity={quantity}
          />
        ) : null}
      </div>
    </li>
  );
};
function QuantityToggler({
  handleDecreaseQuantity,
  quantity,
  handleIncreaseQuantity,
  className,
}: {
  className?: string;
  handleDecreaseQuantity: () => void;
  quantity: number;
  handleIncreaseQuantity: () => void;
}) {
  return (
    <div className={`flex justify-end items-center space-x-2 ${className}`}>
      <button
        onClick={handleDecreaseQuantity}
        className={
          `flex justify-center  items-center bg-primary-yellow text-gray-800 font-semibold  h-6 w-6 border border-primary-yellow rounded-full shadow   ` +
          (quantity == 1
            ? " cursor-not-allowed"
            : " hover:bg-primary-yellow button-bounce")
        }
      >
        <HiOutlineMinusSm color="white" className={"h-4 w-4 "} />
      </button>
      <span className="text-base font-bold select-none">
        {Number(quantity).toFixed(0)}
      </span>
      <button
        onClick={handleIncreaseQuantity}
        className={`bg-primary-yellow flex justify-center  items-center text-gray-800 font-semibold  h-6 w-6 border border-primary-yellow rounded-full shadow  button-bounce`}
      >
        <HiOutlinePlusSm color="white" className={"h-4 w-4 "} />
      </button>
    </div>
  );
}
