import React, { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import MobileContainer from "../mobile-container";



interface IProps {
	showDrawer: boolean;
	setShowDrawer: (val: boolean) => void;
	children: React.ReactNode;
	
	

}



const Drawer: React.FC<IProps> = ({ showDrawer, setShowDrawer, children }) => {
	return (
		<div className="">
			{showDrawer && (
				<div
					data-aos={showDrawer ? "fade-right" : "fade-left"}
					className={`w-full absolute bg-white justify-end z-[999] flex-col items-end h-screen ${
						showDrawer
							? "left-0 right-0 top-0 transition-transform duration-1000 ease-in-out"
							: "translate-x-14"
					} shadow-lg overflow-hidden`}
				>
					<div
						className="flex items-center justify-end p-5 cursor-pointer"
						onClick={() => setShowDrawer(!showDrawer)}
					>
						<IoMdClose size={20} />
					</div>
					<div className="p-4">{children}</div>
					
				</div>
			)}
		</div>
	);
};

export default React.memo(Drawer);
