import {
  CategoryList,
  MenuList,
  MobileHome,
  OrderPlaced,
  OrderReceipt,
  ViewOrder,
} from "./pages/mobile-view";
import FeedbackPage from "./pages/mobile-view/Review";
import PageNotFound from "./pages/PageNotFound";


export const routeData = [

  {
    id: 1,
    element: <FeedbackPage/>,
    exact:true,
    path: "/review",
  },
  {
    id: 1,
    element: <MobileHome />,
    path: "/:venuSlug",
  },
  {
    id: 2,
    element: <CategoryList />,
    path: "/category/:venuSlug",
  },
  {
    id: 3,
    element: <MenuList />,
    path: "/:venuSlug/:zone/menu-list/:categoryId",
  },

  {
    id: 4,
    element: <OrderPlaced />,
    path: "/:venuSlug/:zone/order-placed/:orderId",
  },
  {
    id: 5,
    element: <OrderReceipt />,
    path: "/:zone/:venuSlug/order-receipt/:orderId",
  },
  {
    id: 6,
    element: <ViewOrder />,
    path: "/:zone/view-order/:orderId",
  },
 

];
