import { API_BASE_URL } from "../../constant";
import { Service, ServiceCart } from "../network";
import axios from "axios";

const API_BASE_URL_MOB = "https://qr-api.thecodeedev.com/api/v1";
// https://qr-api.thecodeedev.com
const API_BEARER_TOKEN =
  "Bearer 8|xHFtdEX1dFZqG5xxXZgvjCc7YPQ3JKSRnD6rqiUS35ce5b44";

// export const addToCardMob = async (productId: string, quantity: number) => {
//   try {
//     const response = await Service.post("/cart/add", {
//       productId,
//       quantity,
//     });

//     console.log("Response:", response.data);
//     return response.data;
//   } catch (error) {
//     // Handle error
//     console.error("Error adding to card:", error);
//     throw new Error("Failed to add to card");
//   }
// };

export const getProductsMenuData = async ({categoryId,venuSlug,currentPage,selectSubCatId}: any) => {
  try {
    const response = await Service.get(`/business_detail/${venuSlug}/menus?with_addons=true&cat=${categoryId}&sub_cat=${selectSubCatId}`,{
      params:{
        page:currentPage,
        with_diet:true,
        with_image:true,
        with_varient:true,
      }
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error adding to card:", error);
    throw new Error("Failed to add to card");
  }
};

// Cart api end points

let guestId = localStorage.getItem("guestIdMob")
  ? localStorage.getItem("guestIdMob")
  : null;

const setGuestIdInLocalStorage = (id: any) => {
  localStorage.setItem("guestIdMob", id);
};

const postProductFetchGuestId = async (productId: any, quantity: any) => {
  try {
    const response = await ServiceCart.post("/cart/add", {
      productId: productId,
      quantity: quantity,
    });

    guestId = response?.data?.data?.session_id; // Store the received guestId for subsequent requests
    setGuestIdInLocalStorage(guestId);
    return response?.data;
  } catch (error) {
    console.error("Error fetching guestId:", error);
    throw new Error("Failed to fetch guestId");
  }
};

const handleAPIRequest = async (url: string, method: any, data: any = null) => {
  // Ensure guestId is available
  if (guestId === undefined || guestId === null || !guestId) {
    return await postProductFetchGuestId(data?.productId, data?.quantity);
  } else if (guestId) {
    try {
      const headers: any = {
        "Content-Type": "application/json",
        guestId: guestId,
        // Authorization: API_BEARER_TOKEN,
      };

      const response = await axios({
        method: method,
        url: url,
        headers: headers,
        data: data,
      });

      return response.data;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("API request failed");
    }
  } else {
    console.log("Something went wrong");
  }
};

// Functions for different types of requests

export const addToCartMob = async (productId: any, quantity: any) => {
  const url = `${API_BASE_URL_MOB}/cart/add`;
  return handleAPIRequest(url, "POST", { productId, quantity });
};

export const updateCartItemMob = async (
  cartItemId: string,
  updatedData: any
) => {
  const url = `${API_BASE_URL_MOB}/cart/update`;
  return handleAPIRequest(`${url}/${cartItemId}`, "PUT", updatedData);
};

export const deleteCartItemMob = async (
  productId: number,
  productKey: string
) => {
  const url = `${API_BASE_URL_MOB}/cart/delete`;
  return handleAPIRequest(url, "POST", { productId, productKey });
};

export const getCartItemsMob = async () => {
  const url = `${API_BASE_URL_MOB}/cart`;
  return handleAPIRequest(url, "GET");
};

export const createOrderMob = async (
  {kitchen_note,name,phone,cart_id}:any
) => {
return   Service.post('/order',{
    cart_id,
    kitchen_note,
    name,
    phone,
  }).then(res=>res.data.data).catch(err=>err)
  // const url = `${API_BASE_URL}/order/create`;
  // return handleAPIRequest(url, "POST", {
  //   tableNumber,
  //   kitchen_note,
  //   name,
  //   phone,
  //   zone,
  // });
};

// Example usage:
// addToCart("yourProductId", 2)
//   .then((response) => {
//     console.log("Added to cart:", response);
//   })
//   .catch((error) => {
//     console.error("Error:", error);
//   });
