import React from "react";
import { Footer, MobileContainer } from "../../../mobile-view-components";
import { HiChevronDown, HiOutlineMinusSm } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const orderData = [
  {
    id: 0,
    title: "Arancini al prosciutto",
    price: "NRs 100",
  },
  {
    id: 1,
    title: "Truffle Addiction",
    price: "NRs 200",
  },
];
const ViewOrder = () => {
  const navigate = useNavigate();
  const tableNumberReducer = useSelector(
    (state: any) => state?.addTableNumberMobile.tableNumber
  );

  const orderLength = orderData?.length;

  const handleMenuListNavigate = () => {
    navigate("/mobile/");
  };

  return (
    <div className="bg-gray-200 relative ">
      <div className=" pb-4">
        <div className=" max-w-[480px]  p-5   z-50  bg-white  shadow-lg ">
          <div className="flex items-center justify-between ">
            <img
              src="/Images/logo.svg"
              alt=""
              className="h-full object-cover"
            />
            <div className="">
              {/* <div
              onClick={handleModalClick}
              className="cursor-pointer bg-gray-300 rounded-lg py-1 font-bold text-center mb-2 mt-1"
            >
              Select
            </div> */}
              <h4 className="text-xs">
                {tableNumberReducer ? tableNumberReducer : ""}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between px-8 items-center pt-6 pb-4 rounded-2xl shadow-md bg-white sticky top-0  ">
        <h1 className="text-xl font-semibold ">Your Order</h1>
        <HiChevronDown
          onClick={handleMenuListNavigate}
          className="h-8 w-8 -mt-2 cursor-pointer"
        />
      </div>

      <div className="pt-4 bg-gray-200">
        <div className=" flex flex-col mx-4  rounded-2xl px-4  bg-white pt-8 pb-6 space-y-8">
          {orderData?.map((item) => {
            return (
              <div key={item?.id}>
                <div className="flex justify-between  ">
                  <h1 className="text-xl font-semibold mt-1">{item?.title}</h1>
                  <div className="h-8 w-8 bg-black rounded-full flex justify-center items-center">
                    {orderLength && (
                      <span className=" text-white ">{orderLength}</span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between py-4 ">
                  <div className="flex space-x-2 items-center">
                    <div className="border-2 border-gray-300 rounded-full p-1 cursor-pointer">
                      <HiOutlineMinusSm className="h-4 w-4" />
                    </div>
                    <span>remove</span>
                  </div>

                  <span>{item?.price}</span>
                </div>
              </div>
            );
          })}
          {/* Service charge section */}
          <div>
            <div className="border-t-2 pt-6 pb-4 flex justify-between">
              <h1 className="text-xl font-semibold">
                optional 12.5% service charge
              </h1>
              <span>NRs 100</span>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="border-2 border-gray-300 rounded-full p-1 cursor-pointer">
                <HiOutlineMinusSm className="h-4 w-4" />
              </div>
              <span>remove</span>
            </div>
          </div>

          {/* Note Section */}
          <div className="mt-8">
            <h1 className="text-base font-normal text-gray-500">Note</h1>
            <input placeholder="add a note..." className="outline-none"></input>
          </div>
        </div>

        {/* Total cost section */}

        <div className="py-6 flex justify-between px-4">
          <h1 className="text-2xl font-medium">Total</h1>
          <span className="text-2xl font-medium"> Nrs 100</span>
        </div>
      </div>

      {/* Footer section */}
      <Footer />
    </div>
  );
};

export default ViewOrder;
