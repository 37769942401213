import React, { useEffect, useRef, useState } from "react";
import {
  BannerImage,
  LoaderApiFetch,
  ParagraphText,
  TitleText,
} from "../../../mobile-view-components";
import { MobileBannerImageUrl } from "../../../mobile-view-components/_constants";
import { HiFire, HiOutlineDownload } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  availableCurrency,
  getCurrentFormattedDate,
} from "../../../mobile-view-components/_helpers";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getSingleOrder } from "../../../store/api/order";
import { StateType } from "../../../store/Reducer/index.reducer";
import Banner from "../../../components/Banner";

const OrderReceipt = () => {
  const { zone, venuSlug, orderId } = useParams();
  const navigate = useNavigate();
  const formattedDate = getCurrentFormattedDate();
  const dispatch: any = useDispatch();
  const [isDownloading, setIsDownloading] = useState<Boolean>(false);
  const { order, loader } = useSelector((state: StateType) => state.order);

  const tableDataNumberReducer = useSelector(
    (state: any) => state?.addTableNumberMobile.tableNumber
  );

  useEffect(() => {
    dispatch(getSingleOrder(orderId as string));
  }, [dispatch, orderId]);

  const handleNavigation = () => {
    localStorage.removeItem("userOrderPlacedDataMob");
    localStorage.removeItem("orderPlacedByUserMob");
    localStorage.removeItem("guestIdMob");
    navigate(`/category/${venuSlug}?zone=${zone}`);
    // window.location.reload()
  };

  // Download pdf
  const contentRef = useRef(null);

  const handleDownloadPDF = () => {
    console.log("clicked");
    setIsDownloading(true);
    const element: any = contentRef.current;

    html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new (jsPDF as any)("p", "mm", "a4");

        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        pdf.save("Order-Receipt.pdf");
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error("Error generating PDF:", error);
      });
  };
  const BannerPart = () => (
    <Banner>
      {" "}
      <BannerImage imageUrl={MobileBannerImageUrl} />
    </Banner>
  );
  const DetailPart = () => (
    <div className="h-full" style={{ marginTop: "-40px" }}>
      <div className="px-4 max-w-md mx-auto " ref={contentRef}>
        <ParagraphText className="py-2 text-center font-lg">
          {formattedDate}
        </ParagraphText>
        <TitleText className="py-2  text-center  font-bold text-xl  mb-0">
          Order Summary
        </TitleText>

        {[
          {
            title: "Bill Details",
            lists: [
              {
                label: "Items",
                value: order?.item_count,
              },
              {
                label: "Sub Total",
                currency: availableCurrency.npr,

                value: order?.subtotal,
              },
              {
                label: "Tax",
                currency: availableCurrency.npr,

                value: order?.tax_total,
              },
              {
                label: "Grand Total",
                currency: availableCurrency.npr,

                value: order?.total,
              },
              {
                label: "To Pay",
                currency: availableCurrency.npr,
                value: order?.total,
              },
            ],
          },
          {
            title: "Payment Details",
            border: false,
            lists: [
              {
                label: "Payment Method",
                value: order?.payment_method,
              },
              {
                label: "Payment Status",
                value: order?.status === "new" ? "Pending" : "Unknown",
              },
            ],
          },
        ].map(({ title, lists, border }, index: number) => {
          return (
            <div key={index} className="last:mt-6 first:mt-none">
              <h2 className="text-black font-bold text-lg">{title}</h2>
              <div className="bg-gray-100 rounded-md  px-3 py-3 pb-1 mt-2">
                <ul>
                  {lists.map(
                    ({ label, value, currency }: any, index: number) => (
                      <li
                        key={index}
                        className={`flex justify-between font-bold pb-2 mt-2 ${
                          border
                            ? " last:border-none last:pb-0 border-b-2 border-b-gray-200"
                            : ""
                        }`}
                      >
                        <span>{label}</span>
                        <span>
                          {currency}{" "}
                          {typeof value === "string"
                            ? value?.charAt(0)?.toUpperCase() + value?.slice(1)
                            : value}
                        </span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-6 px-4 max-w-md mx-auto">
        <h2 className="text-black font-bold text-lg">Ordered Items</h2>
        <div>
          {order?.order_items?.map((item: any, index: any) => {
            return (
              <div
                key={item?.id}
                className="flex justify-between item-center py-8 border-t-2 last:border-b-2 border-y-gray-200 last:border-b-none"
              >
                <div>
                  {item?.is_popular && (
                    <div className="p-1 bg-primary-yellow w-fit rounded-full ">
                      {item?.is_popular ? (
                        <HiFire className="h-4 w-4 text-white" />
                      ) : null}
                    </div>
                  )}
                  <h2 className="text-base font-bold mb-0">
                    {item?.menu_name}
                  </h2>
                  <h2 className="text-sm font-bold mb-1 ">Starters</h2>
                  <div>
                    <span className="text-xs text-gray-600 font-bold">
                      {item?.quantity}
                    </span>{" "}
                    <span className="text-md font-bold text-orange-500">
                      {availableCurrency["npr"]} {item?.price}
                    </span>
                  </div>
                </div>
                <div className="relative h-28 w-32 cursor-pointer">
                  {/* <img
                    src={
                      item?.images?.[0] ||
                      "https://api.orderlyinn.com/images/category/e84fb377-b09d-4bae-8670-95aa5452f29f.jpg"
                    }
                    className="absolute h-full w-full object-cover rounded-md "
                  /> */}
                  <img
                    src={item?.images[0].path || "placeholder.jpg"}
                    className="absolute h-full w-full object-cover rounded-md "
                  />

                  <div className="absolute p-1 bg-white shadow-md h-12 w-20 -bottom-4 border border-orange-500 left-6 rounded-md  font-bold text-orange-500 text-center">
                    <div className="text-sm font-bold">
                      {" "}
                      <span className="text-xs">
                        {availableCurrency["npr"]}
                      </span>{" "}
                      <br></br> {item?.total}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="flex justify-center items-center space-x-3"
        onClick={handleDownloadPDF}
      >
        <HiOutlineDownload className="h-6 w-6 text-blue-400 cursor-pointer" />
        <ParagraphText className="text-blue-400 my-6 font-medium text-sm cursor-pointer">
          {isDownloading ? "Downloading..." : " Download Receipt"}
        </ParagraphText>
      </div>
    </div>
  );

  return (
    <div className="">
      <BannerPart />
      <DetailPart />

      {(isDownloading || loader) && <LoaderApiFetch />}
    </div>
  );
};

export default OrderReceipt;
