import {
    ADD_TO_CART,
    EMPTY_CART,
    GET_CART,
    loaderAction,
    REMOVE_FROM_CART,
} from "../const";
import { CartType } from "../Type/cart.type";

export interface CartReducar {
    product: Array<CartType>;
    subTotal: number;
    venueCharge: number;
    tax: number;
    containerCharge: number;
    service_charge: number;
    tax_amount: number;
    total_before_tax: number;
    total: number;
    totalQty: number;
    session_id: string;
    loader: boolean;
} 
const initialData: any = {
    product: [],
    subTotal: 0,
    venueCharge: 0,
    tax: 0,
    containerCharge: 0,
    service_charge: 0,
    tax_amount: 0,
    total_before_tax: 0,
    total: 0,
    totalQty: 0,
    session_id: "",
    loader: false,
};
const cartReducer = (state = initialData, action: any) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                cart:{
                    ...action.payload
                }
                // product: action.payload.cart,
                // total: action.payload.total,
                // subTotal: action.payload.subTotal,
                // venueCharge: action.payload.venueCharge,
                // tax: action.payload.tax,
                // service_charge: action.payload.service_charge,
                // tax_amount: action.payload.tax_amount,
                // total_before_tax: action.payload.total_before_tax,
                // containerCharge: action.payload.containerCharge,
                // session_id: action.payload.session_id,
                // totalQty: action.payload.cart.reduce(
                //     (prev: number, current: CartType) =>
                //         prev + +current.quantity,
                //     0
                // ),
                // loader: false,
            };
        case GET_CART:
            return {
                ...state,
                product: action.payload.cart,
                total: action.payload.total,
                subTotal: action.payload.subTotal,
                venueCharge: action.payload.venueCharge,
                service_charge: action.payload.service_charge,
                tax_amount: action.payload.tax_amount,
                total_before_tax: action.payload.total_before_tax,
                tax: action.payload.tax,
                containerCharge: action.payload.containerCharge,
                session_id: action.payload.session_id,

                totalQty: action.payload.cart.reduce(
                    (prev: number, current: CartType) =>
                        prev + +current.quantity,
                    0
                ),
                loader: false,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                product: action.payload.cart,

                total: action.payload.total,
                subTotal: action.payload.subTotal,
                venueCharge: action.payload.venueCharge,
                tax: action.payload.tax,
                containerCharge: action.payload.containerCharge,
                session_id: action.payload.session_id,
                totalQty: action.payload.cart.reduce(
                    (prev: number, current: CartType) =>
                        prev + +current.quantity,
                    0
                ),
                loader: false,
            };
        case EMPTY_CART:
            return {
                ...state,
                product: [],
                subTotal: 0,
                venueCharge: 0,
                tax: 0,
                containerCharge: 0,
                total: 0,
                totalQty: 0,
            };

        default:
            return state;
    }
};
export default cartReducer;
