import React, { useEffect, useState } from "react";
import {
  Footer,
  LoaderApiFetch,
  MobileContainer,
  ParagraphText,
  TitleText,
  Modal,
} from "../../../mobile-view-components";
import { HiCheck } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../mobile-view-components/button";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../store/Reducer/index.reducer";
import { getSingleOrder } from "../../../store/api/order";

const OrderPlaced = () => {
  const navigate = useNavigate();
  const { venuSlug, zone, orderId } = useParams();
  const dispatch: any = useDispatch();

  const { order, loader } = useSelector((state: StateType) => state.order);
  const [removeOrderId, setRemoveOrderId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (orderId && !order) dispatch(getSingleOrder(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    setRemoveOrderId(orderId);
  }, [removeOrderId]);

  const handleViewReceipt = () => {
    navigate(`/${zone}/${venuSlug}/order-receipt/` + orderId);
  };

  const handleCancelOrder = () => {
    setRemoveOrderId(undefined);
    navigate(`/category/${venuSlug}?zone=${zone}`);
  };

  const handleHomeNavigation = () => {
    // localStorage.removeItem("userOrderPlacedDataMob");
    // localStorage.removeItem("orderPlacedByUserMob");
    // localStorage.removeItem("guestIdMob");
    localStorage.removeItem("tableNumber");
    navigate(`/category/${venuSlug}?zone=${zone}`);
  };
 

  return (
    <div className=" flex flex-col justify-between flex-1">
      {loader ? (
        <LoaderApiFetch />
      ) : (
        <div className="text-center pt-8 ">
          <div className="flex justify-center items-center pb-6">
            <div className="p-6 bg-gray-100 w-fit  rounded-full">
              <div className="p-2 border-8 border-green-700 rounded-full w-fit bg-white">
                <div className="bg-green-700 rounded-full w-fit text-white p-6">
                  <img src="/Animation.gif" alt="animation" />
                </div>
              </div>
            </div>
          </div>

          <TitleText className="font-extrabold text-2xl mb-2">
            {" "}
            Order Placed Successfully!{" "}
          </TitleText>

          <ParagraphText className="font-bold">
            {" "}
            
            Total Bill Amount NRs.{order?.total}
            
        
          </ParagraphText>
      

          {/* Button section */}

          <div className=" pt-4 pb-4">
            {/* <div className="flex justify-center items-center">
            <Button
              isValid={true}
              text="View receipt"
              type="submit"
              onClick={handleViewReceipt}
              variant="primary"
              className="w-[95%] py-2.5 mb-3 px-4"
            />
          </div> */}

            <div className="flex justify-center items-center">
              <Button
                isValid={true}
                text="View Order"
                type="submit"
                onClick={handleViewReceipt}
                variant="primary"
                className="w-[95%] py-2.5 mb-3 px-4"
              />
            </div>
            <div className="flex justify-center items-center">
              <Button
                isValid={true}
                text="Cancel Order"
                type="submit"
                onClick={handleCancelOrder}
                variant="primary"
                className="w-[95%] py-2.5 mb-3 px-4"
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <ParagraphText
              onClick={handleHomeNavigation}
              className="cursor-pointer w-fit text-gray-500 border-b-2 border-b-white hover:border-b-black pb-[1px]"
            >
              Back to Menu
            </ParagraphText>
          </div>
        </div>
      )}

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default OrderPlaced;
