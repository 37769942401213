export interface IvenuServiceReducer{
  loading:boolean,
  services:any[]
}
const initialState:IvenuServiceReducer ={
loading:true,
services:[]
} 

export const SET_VENUE_SERVICE_LOADING = 'SET_VENUE_SERVICE_LOADING'

export const SET_VENUE_SERVICE = 'SET_VENUE_SERVICE' 

export const venuServiceReducer = (state=initialState,action:any)=>{
  switch(action.type){
    case SET_VENUE_SERVICE_LOADING:
       return {...state,loading:action.payload}
    case SET_VENUE_SERVICE:
      return {...state,services:action.payload.data}
    default:
    return state
  }
}