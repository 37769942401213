import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  BannerImage,
  Card,
  Footer,
  LoaderApiFetch,
  Modal,
  ParagraphText,
  ProduDetailsModal,
  ProductCard,
  SkeletonLoaderCartItems,
  SkeletonLoaderMenuBar,
  SkeletonLoaderMenuProducts,
  // SkeletonLoaderMenuProducts,
  TitleText,
} from "../../../mobile-view-components";
import {
  HiChevronDown,
  HiOutlineChevronDown,
  HiOutlineMinusSm,
} from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { MobileBannerImageUrl } from "../../../mobile-view-components/_constants";
import Button from "../../../mobile-view-components/button";
import useFetch from "../../../mobile-view/hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import {
  createOrderMob,
  getProductsMenuData,
} from "../../../mobile-view/_api_mob";
import { useDispatch, useSelector } from "react-redux";
import {
  availableCurrency,
  capitalizeName,
} from "../../../mobile-view-components/_helpers";
import { getVenueService } from "../../../store/api/venuService";
import { StateType } from "../../../store/Reducer/index.reducer";
import {
  ICartPayload,
  getCart,
  handleAddToCart,
  handleDelete,
  handleDeleteAddonsFromCartItem,
} from "../../../store/api/cart";
import { ADD_TO_CART, orderAction } from "../../../store/const";
import { Tabs } from "antd";
import Banner, { TableNumberSection } from "../../../components/Banner";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppLoader } from "../../../components/Loader/app.loader";
import { MobileContainerContext } from "../../../mobile-view-components/mobile-container";
interface IProps {}

const useFetchMenuListHook = (
  currentPage: string | number,
  categoryId: string | undefined,
  currentab: string | undefined,
  venuSlug: string | undefined
): [boolean, any[], any] => {
  const [menuslist, setMenuList] = useState<any>([]);
  const [isMenuListLoading, setIsMenuListLoading] = useState(true);
  const [meta, setMeta] = useState(1);

  useEffect(() => {
    if (categoryId && !!Boolean(currentab)) {
      setIsMenuListLoading(true);
      const fetchMenuData = async () => {
        try {
          const { data, meta } = await getProductsMenuData({
            categoryId,
            venuSlug,
            currentPage,
            selectSubCatId: currentab,
          }); // Replace with your API endpoint for menu
          if (Number(currentPage) > 1) {
            setMenuList([...menuslist, ...data]);
          } else {
            setMenuList(data);
          }
          setMeta(meta);
          if (data) {
            setIsMenuListLoading(false);
          }
        } catch (error) {
          console.error("Error fetching menu data:", error);
        }
      };

      fetchMenuData();
    }
  }, [categoryId, currentab, currentPage]);

  return [isMenuListLoading, menuslist, meta];
};

const MenuList: React.FC<IProps> = ({}) => {
  const navigate = useNavigate();
  const tableNumberReducer = useSelector(
    (state: any) => state?.addTableNumberMobile.tableNumber
  );
  const [isApiCallLoading, setIsApiCallLoading] = useState<Boolean>(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] =
    useState<Boolean>(false);
  // min
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showProductsCartView, setShowProductsCartView] = useState(false);

  const [productScrollTarget, setProductScrollTarget] = useState<any>();

  const [singleProductDetails, setSingleProductDetails] = useState();

  const { venuSlug, zone, categoryId } = useParams();

  const { cart: cartOrderData, loader } = useSelector(
    (state: StateType) => state.cart as any
  );
  const [kitchenNote, setKitchenNote] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");
  const dispatch: any = useDispatch();
  const { services: zones } = useSelector(
    (state: StateType) => state.venuService
  );
  const [currentab, setCurrentab] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);


  const {isProfileLoading} = useContext(MobileContainerContext)

  const selectedZone = useMemo(() => {
    if (zones.length) {
      return zones.find((i) => i.slug === zone);
    }
  }, [zones]);

  // Get category Dat`a
  const { data: categoryDataFetch, isLoading } = useFetch(
    selectedZone
      ? `/business_detail/${venuSlug}/category?service_zone=${selectedZone?.id}&withsubcat=true`
      : ""
  );
  const selectedCategory = useMemo(() => {
    if (categoryDataFetch?.data) {
      let cat = categoryDataFetch.data.find((i: any) => i.id == categoryId);
      if (!cat) {
        return { name: "Results not found" };
      }
      return cat;
    }
    return null;
  }, [categoryDataFetch?.data?.length, categoryId]);
  // get zones

  React.useEffect(() => {
    if (venuSlug && !zones?.length) dispatch(getVenueService(venuSlug));
  }, [venuSlug]);

  useEffect(() => {
    setCurrentab(selectedCategory?.subcategories?.[0]?.id);
  }, [selectedCategory]);

  const [isProductLoading, menuProductsData, meta] = useFetchMenuListHook(
    currentPage,
    categoryId,
    currentab,
    venuSlug
  );

  // initial index will check for active Subcateogry

  const handleModalClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (!isModalOpen && productScrollTarget) {
      const element = document.getElementById(productScrollTarget);

      setProductScrollTarget(null);
    }

    if (isCategoryModalOpen) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "scroll";
    }
  }, [isModalOpen, isCategoryModalOpen]);

  // View Card
  const handleViewCardProduct = () => {
    setShowProductsCartView((prev) => !prev);
  };

  const handleSubCategoryOptions = (item: any) => {
    if (item?.id?.toString() !== categoryId?.toString()) {
      setCurrentab("");
    }
    setIsCategoryModalOpen(false);
    navigate(`/${venuSlug}/${zone}/menu-list/${item?.id}`);
  };

  // Order logics
  // const orderLength = 1;

  // Get order list menu

  useEffect(() => {
    if (selectedZone && localStorage.getItem("cart_id") && !cartOrderData)
      dispatch(getCart(selectedZone.slug));
  }, [selectedZone, localStorage.getItem("cart_id"), cartOrderData]);

  const handleMenuOrderCheckout = async (e: any) => {
    e.preventDefault();
    setIsApiCallLoading(true);
    try {
      const data = await createOrderMob({
        kitchen_note: kitchenNote,
        name: userName,
        phone: userPhoneNumber,
        cart_id: cartOrderData?.id,
      });
      dispatch({ type: orderAction.CREATE_ORDER, payload: data });
      localStorage.removeItem("cart_id");
      dispatch({ type: ADD_TO_CART, payload: [] });
      navigate(`/${venuSlug}/${zone}/order-placed/${data.order_id}`);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderParam = urlParams.get("orderlist");
    if (orderParam === "true") {
      setShowProductsCartView(true);
    }
  }, []);
  // Handle phone number to capitalize
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.value;
    const formatted = capitalizeName(inputName);
    setUserName(formatted);
  };

  //handle phonenumber change
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    const trimmedInput = numericInput.slice(0, 10); // Limit to 10 digits
    setUserPhoneNumber(trimmedInput);
  };

  const handleNumberInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isNumber = /^\d$/.test(e.key);
    const isDeleteOrBackspace = e.key === "Delete" || e.key === "Backspace";
    const isValidLength =
      e.currentTarget.value.replace(/\D/g, "").length < 10 ||
      isDeleteOrBackspace;

    if (!isNumber && !isDeleteOrBackspace) {
      e.preventDefault();
    } else if (!isValidLength) {
      e.preventDefault();
    }
  };
  function addToCart(data: ICartPayload) {
    dispatch(
      handleAddToCart(data, {
        business: venuSlug as string,
        service_zone: selectedZone.slug as string,
        // cart_id:'65a252a6-7fd9-4bb1-975f-a202944a8f58',
        order_from: tableNumberReducer,
      })
    );
  }
  return (
    <AppLoader isLoading={isProfileLoading}>
      {!showProductsCartView ? (
        <>
          <div className="relative h-full flex flex-col  justify-between flex-1 ">
            <div>
              {!isModalOpen && (
                <>
                  <div>
                    <Banner>
                      <BannerImage />
                    </Banner>
                    <TableNumberSection />

                    <div className="relative w-full  sm:max-w-[480px]">
                      {/* Menu bar section */}

                      {isLoading || !selectedCategory ? (
                        <div className="mt-5">
                          <SkeletonLoaderMenuBar />
                        </div>
                      ) : (
                        <>
                          <div className="flex sticky top-0 bg-white py-1  z-20 space-x-6 mt-6 items-center mx-1.5">
                            {!isLoading && (
                              <div
                                onClick={() => setIsCategoryModalOpen(true)}
                                className="flex background-yellow items-center justify-center rounded-xl py-2 px-3 space-x-1 cursor-pointer text-white "
                              >
                                <TitleText className=" font-semibold text-sm whitespace-nowrap ">
                                  {selectedCategory?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                    selectedCategory?.name.slice(1)}
                                </TitleText>

                                <HiOutlineChevronDown className="h-4 w-4  " />
                              </div>
                            )}
                            <div className="overflow-hidden">
                              <Tabs
                                hideAdd={false}
                                className="capitalize"
                                animated={false}
                                tabPosition="top"
                                activeKey={currentab}
                                onChange={(activeKey: string) => (
                                  setCurrentPage(1), setCurrentab(activeKey)
                                )}
                                items={selectedCategory?.subcategories?.map(
                                  ({ name, id }: any, i: number) => {
                                    return {
                                      label: name,
                                      key: id as string,
                                    };
                                  }
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <>
                        <InfiniteScroll
                          className="flex flex-col gap-[1.5rem] p-5 !h-fit !overflow-visible mt-2"
                          next={() => {
                            setCurrentPage((prev) => prev + 1);
                          }}
                          dataLength={menuProductsData?.length || 0}
                          hasMore={(meta?.total || 0) != (meta?.to || 0)}
                          loader={
                            <div className="flex mt-12">
                              <SkeletonLoaderMenuProducts count={6} />
                            </div>
                          }
                        >
                          {isProductLoading && currentPage === 1 ? (
                            <div className="flex mt-12">
                              <SkeletonLoaderMenuProducts count={6} />
                            </div>
                          ) : !menuProductsData?.length ? (
                            <h3 className="text-sm text-gray">
                              No Result Found
                            </h3>
                          ) : (
                            menuProductsData?.map(
                              (item: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <ProductCard
                                      id={item?.id}
                                      item={item}
                                      name={item?.name}
                                      description={item?.description}
                                      price={item?.price}
                                      tags={item?.tags}
                                      imageUrl={item?.image}
                                      imageAlt={item?.imageUrl}
                                      isModalOpen={isModalOpen}
                                      setIsModalOpen={setIsModalOpen}
                                      setProductScrollTarget={
                                        setProductScrollTarget
                                      }
                                      setSingleProductDetails={
                                        setSingleProductDetails
                                      }
                                    />
                                  </div>
                                );
                              }
                            )
                          )}
                        </InfiniteScroll>
                      </>
                    </div>

                    {/* Product details modal */}

                    <div
                      className="fixed  bottom-0 flex justify-center items-center transform left-1/2  -translate-x-1/2  
               z-50  w-full  sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px]  ]
              "
                    >
                      <Button
                        isValid={true}
                        text="View Your Order"
                        type="submit"
                        onClick={() => handleViewCardProduct()}
                        variant="primary"
                        className="w-[95%] py-2.5 mb-3 px-4"
                        isDisabled={
                          cartOrderData?.items?.length > 0 ? false : true
                        }
                      />

                      <TitleText className=" absolute z-50 right-12 top-[6px] text-lg font-medium text-white">
                        {cartOrderData?.items?.length || 0}
                      </TitleText>
                    </div>
                  </div>
                </>
              )}

              {isModalOpen && (
                <Modal
                  data-aos="fade-up"
                  modalClose={handleModalClick}
                  bgNormal
                  position="top"
                  mx="0"
                  className="bg-white"
                >
                  <ProduDetailsModal
                    item={singleProductDetails}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    addToCart={addToCart}
                    target={productScrollTarget}
                    fetchCartItemsMob={() => {
                      "fetchCartItemsMob";
                    }}
                    setIsApiCallLoading={setIsApiCallLoading}
                    isApiCallLoading={isApiCallLoading}
                    startTime={selectedCategory?.start_time}
                    endTime={selectedCategory?.end_time}
                    canOrder={selectedCategory?.can_order}
                  />
                </Modal>
              )}

              {isCategoryModalOpen && (
                <Modal
                  modalClose={() => setIsCategoryModalOpen(false)}
                  bgNormal
                >
                  <div className="border-b-2 overscroll-contain border-gray-300 flex items-center justify-between py-3 px-4 shadow">
                    {/* <HiArrowNarrowLeft size={20} className="cursor-pointer" /> */}
                    <TitleText className="font-semibold text-lg mt-3">
                      What do you like to order?
                    </TitleText>
                    <IoMdClose
                      size={20}
                      onClick={() => setIsCategoryModalOpen(false)}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="mb-10 px-4 max-h-[400px] min-h-fit  overscroll-contain overflow-y-scroll ">
                    {categoryDataFetch?.data?.map((item: any) => (
                      <div
                        className="flex items-center justify-between border-b border-gray-300 last:border-0 py-3"
                        key={item?.id}
                        onClick={() => handleSubCategoryOptions(item)}
                      >
                        <ParagraphText className="capitalize cursor-pointer">
                          {item?.name}
                        </ParagraphText>
                      </div>
                    ))}
                  </div>
                </Modal>
              )}
            </div>

            {/* Footer */}

            <Footer className="pb-10" />
          </div>
        </>
      ) : (
        <>
          <div className="relative bg-gray-200  flex flex-col  justify-between flex-1 ">
            <div>
              <div className=" pb-4">
                <div className=" w-full sm:max-w-[480px]  p-5   z-50  bg-white  shadow-lg ">
                  <div className="flex items-center justify-between ">
                    <img
                      src="/Images/logo.svg"
                      alt=""
                      className="h-full object-cover"
                    />
                    <div className="">
                      {/* <div
                      onClick={handleModalClick}
                      className="cursor-pointer bg-gray-300 rounded-lg py-1 font-bold text-center mb-2 mt-1"
                    >
                      Select
                    </div> */}
                      <div className="flex space-x-2 items-center">
                        <div className="cursor-pointer  text-black text-sm rounded-lg  font-bold text-center  ">
                          Table No.
                        </div>

                        <ParagraphText className=" font-semibold text-base">
                          {tableNumberReducer}
                        </ParagraphText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-8 items-center pt-6 pb-4 rounded-2xl shadow-md bg-white sticky top-0  ">
                <TitleText className="text-lg font-medium h-8">
                  Your Order
                </TitleText>
                <HiChevronDown
                  onClick={handleViewCardProduct}
                  className="h-8 w-8 -mt-2 cursor-pointer"
                />
              </div>

              <form onSubmit={handleMenuOrderCheckout}>
                <div className="pt-4 bg-gray-200">
                  <div className=" flex flex-col mx-4  rounded-2xl px-4  bg-white pt-8 pb-6 space-y-8">
                    {loader ? (
                      <div>
                        <SkeletonLoaderCartItems count={2} />
                      </div>
                    ) : (
                      <>
                        {cartOrderData?.items?.length > 0 &&
                          cartOrderData?.items?.map(
                            (item: any, index: number) => {
                              return (
                                <div key={index}>
                                  <div className="flex justify-between items-center ">
                                    {/* <ParagraphText  className='text-lg font-medium mt-1'>
                                                                            {
                                                                                item?.name
                                                                            }
                                                                        </ParagraphText> */}
                                    <div className="flex gap-[2.6rem]">
                                      <span
                                        className={
                                          "flex-grow-0  w-[140px] font-AvenirLTLight text-[18px] font-bold mt-1"
                                        }
                                        style={{
                                          textAlign: "start",
                                        }}
                                      >
                                        {item?.name?.charAt(0).toUpperCase() +
                                          item?.name?.slice(1)}
                                      </span>
                                    </div>
                                    <span className="text-small flex self-center">
                                      <span>
                                        {" "}
                                        {availableCurrency.npr}{" "}
                                        {Number(item?.price).toFixed(0)}
                                      </span>
                                    </span>
                                    <div className="flex justify-end">
                                      <div className="h-8 w-8 bg-black self-end rounded-full flex justify-center items-center">
                                        <ParagraphText className=" text-white ">
                                          {Number(item?.quantity).toFixed(0)}
                                        </ParagraphText>
                                      </div>
                                    </div>
                                  </div>

                                  <AddonsListMap
                                    mapData={item?.addons || []}
                                    item_id={item?.id}
                                    cart_id={item.cart_id}
                                    dispatch={dispatch}
                                  />

                                  <div className="flex justify-between  cursor-pointer">
                                    <div
                                      className="flex space-x-2 items-center group"
                                      onClick={() => {
                                        dispatch(handleDelete(item.id));
                                      }}
                                    >
                                      <div
                                        className="border-2 border-gray-300 rounded-full p-1 cursor-pointer group-hover:bg-yellow-200 transition-[background-color] 
                   duration-300 ease-in"
                                      >
                                        <RxCross2 className="h-4 w-4" />
                                      </div>
                                      <ParagraphText className="text-xs">
                                        Remove
                                      </ParagraphText>
                                    </div>

                                    <ParagraphText>{item?.price}</ParagraphText>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </>
                    )}
                    {/* Service charge section */}

                    {cartOrderData?.service_charge > 0 && (
                      <div>
                        <div className="border-t-2 pt-6 pb-4 flex justify-between">
                          <ParagraphText className="text-xl font-semibold">
                            optional 12.5% service charge
                          </ParagraphText>
                          <span>NRs {cartOrderData?.service_charge}</span>
                        </div>
                        <div className="flex space-x-2 items-center">
                          <div className="border-2 border-gray-300 rounded-full p-1 cursor-pointer">
                            <HiOutlineMinusSm className="h-4 w-4" />
                          </div>
                          <ParagraphText>remove</ParagraphText>
                        </div>
                      </div>
                    )}
                    {/* Note Section */}
                    <div>
                      <div className="mt-8">
                        <ParagraphText className="text-base font-normal text-gray-500">
                          Name
                        </ParagraphText>
                        <input
                          required
                          placeholder="Enter name"
                          className="outline-none capitalize "
                          value={userName}
                          onChange={handleNameChange}
                        />
                      </div>
                      <div className="mt-8">
                        <ParagraphText className="text-base font-normal text-gray-500">
                          Phone Number
                        </ParagraphText>
                        <input
                          required
                          placeholder="Enter phone number"
                          className="outline-none"
                          value={userPhoneNumber}
                          onChange={handlePhoneNumberChange}
                          onKeyDown={handleNumberInput}
                        />
                      </div>

                      <div className="mt-8">
                        <ParagraphText className="text-base font-normal text-gray-500">
                          Kitchen Note
                        </ParagraphText>
                        <input
                          placeholder="Add a note..."
                          className="outline-none"
                          value={kitchenNote}
                          onChange={(event) =>
                            setKitchenNote(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* Total cost section */}
                  <div className="py-6 flex justify-between px-12">
                    <ParagraphText className="text-base font-semibold">
                      Total
                    </ParagraphText>
                    <ParagraphText className="text-base font-semibold">
                      {" "}
                      Nrs {cartOrderData?.total}
                    </ParagraphText>
                  </div>
                </div>

                {/* Checkout button */}
                <div
                  className="fixed  bottom-0 flex justify-center items-center transform left-1/2  -translate-x-1/2
              
               z-50  w-full  sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px]  ]
              "
                >
                  <Button
                    isValid={true}
                    text="Checkout Order"
                    type="submit"
                    variant="primary"
                    isDisabled={cartOrderData?.items?.length > 0 ? false : true}
                    className="w-[95%] py-2.5 mb-3 px-4"
                  />

                  <TitleText className=" absolute z-50 right-12 top-[6px] text-lg font-medium text-white">
                    {cartOrderData?.items?.length}
                  </TitleText>
                </div>
              </form>
            </div>

            {/* Footer section */}
            <Footer className="pb-10" />
          </div>
        </>
      )}

      <div>{(loader || isApiCallLoading) && <LoaderApiFetch />}</div>
    </AppLoader>
  );
};

export default React.memo(MenuList);

function AddonsListMap({
  mapData,
  cart_id,
  item_id,
  dispatch,
}: {
  mapData: any[];
  item_id: string;
  cart_id: string;
  dispatch: any;
}) {
  return (
    <div>
      <div className="overscroll-none no-scrollbar overflow-y-scroll mt-2">
        <ul className="list-disc">
          {mapData?.map(
            (
              { name, price, id, addon_id, slug, quantity }: any,
              index: number
            ) => (
              <li key={index} className="grid  grid-cols-3  text-ellipsis	mt-1 ">
                <div className="flex items-center gap-[2.6rem]">
                  <span
                    className={
                      "flex-grow-0  w-[160px] text-sm font-AvenirLTLight text-small font-medium mt-1 pl-2"
                    }
                    style={{
                      textAlign: "start",
                    }}
                  >
                    {name?.charAt(0).toUpperCase() + name?.slice(1)}
                  </span>
                </div>
                <span className="text-xs flex items-center justify-start self-center">
                  <span>
                    {" "}
                    {availableCurrency.npr} {Number(price).toFixed(0)}
                  </span>
                </span>
                <div className="flex  items-center justify-end gap-4">
                  <span>Qty:{Number(quantity).toFixed(0)}</span>
                  <span className="flex flex-grow-0 space-x-2 items-center self-center justify-end">
                    <span
                      onClick={() => {
                        dispatch(
                          handleDeleteAddonsFromCartItem({
                            item_id,
                            cart_id,
                            addon_id: id,
                          })
                        );
                      }}
                      className="border-2 border-gray-300 rounded-full p-1  cursor-pointer hover:bg-yellow-200 transition-[background-color] 
                   duration-300 ease-in"
                    >
                      <RxCross2 size={10} className="h-4 w-4" />
                    </span>
                  </span>
                </div>
              </li>
              //             <li
              //                 key={index}
              //                 className='grid grid-cols-3'
              //                 // className=' mb-2  flex  item-center text-ellipsis	 justify-between text-center'
              //                 >
              //                 <span
              //                     className={'flex-grow-0'}
              //                     style={{textAlign: 'start'}}>
              //                     {name?.charAt(0).toUpperCase() +
              //                         name?.slice(1)}
              //                 </span>
              //                 <span
              //                     className='text-small flex justify-center flex gap-5 flex-grow-0 self-center'
              //                     >
              //                     <span>
              //                         {' '}
              //                         {availableCurrency.npr}{' '}
              //                         {Number(price).toFixed(0)}
              //                     </span>
              //                 </span>
              //                 <span>Qt:{Number(quantity).toFixed(0)}</span>
              //                 <span className='flex flex-grow-0 space-x-2 items-center self-center justify-end'>
              //                     <span
              //                         onClick={() => {
              //                             dispatch(
              //                                 handleDeleteAddonsFromCartItem({
              //                                     item_id,
              //                                     cart_id,
              //                                     addon_id: id,
              //                                 })
              //                             )
              //                         }}
              //                         className='border-2 border-gray-300 rounded-full p-1  cursor-pointer hover:bg-yellow-200 transition-[background-color]
              //    duration-300 ease-in'>
              //                         <RxCross2 className='h-4 w-4' />
              //                     </span>
              //                 </span>
              //             </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}
