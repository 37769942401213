import React from "react";
import clsx from "clsx";

interface IProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const TitleText = ({ className, children, onClick }: IProps) => {
  return (
    <>
      <div
        onClick={onClick}
        className={clsx(className, "text-lg font-semibold font-AvenirLTMedium")}
      >
        {children}
      </div>
    </>
  );
};

export default TitleText;
