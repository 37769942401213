import { toast } from "react-toastify";

const notify = (severity:string,content:any)=>{
  
  switch(severity){
   case 'error':
    toast(<span className='flex gap-1 text-sm font-extrabold'>{content}</span>, {
      position: "top-right",
      autoClose: 5000,
      type:'error',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      break;
   case 'success':
   default:
       toast(<span className='flex gap-1'>{content}</span>, {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           progressStyle:{
               background:"rgb(255 170 30 / 1)"
           },
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
           });
           break;
  }
}

export {
  notify
}