import React, { useContext, } from "react";
import {
  BannerImage,
  CategoryCard,
  Footer,
  SkeletonLoaderCategoryItems,
} from "../../../mobile-view-components";

import {  useParams } from "react-router-dom";
import useFetch from "../../../mobile-view/hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";

import { BASE_API_URL } from "../../../constant";
import {
  IcategoryResponseType,
  getVenueService,
} from "../../../store/api/venuService";
import { StateType } from "../../../store/Reducer/index.reducer";
import Banner, { TableNumberSection } from "../../../components/Banner";
import { AppLoader } from "../../../components/Loader/app.loader";
import { MobileContainerContext } from "../../../mobile-view-components/mobile-container";

const CategoryList = () => {
  const { venuSlug } = useParams();
  const dispatch: any = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const zone = urlParams.get("zone");


  const {isProfileLoading} = useContext(MobileContainerContext)

  const { services, loading } = useSelector(
    (state: StateType) => state.venuService
  );
  

  const selectedZone = React.useMemo(() => {
    if (services.length) {
      return services.find((i) => i.slug === zone);
    }
  }, [services,zone]);

  const {
    data: categoryDataFetch,
    isLoading,
    errorMsg,
  } = useFetch(
    selectedZone
      ? `${BASE_API_URL}/business_detail/${venuSlug}/category?service_zone=${selectedZone.id}`
      : ""
  );

  React.useEffect(() => {
    if (!services.length && venuSlug) dispatch(getVenueService(venuSlug));
  }, []);

 
  
  // Call API
  return (
    <AppLoader isLoading={isProfileLoading}>
      <div className="relative flex flex-col  justify-between flex-1 ">
        <div>
          {
            <>
              <Banner>
                <BannerImage /> 
                </Banner>
                <TableNumberSection  /> 
               

              {isLoading ? (
                <div className="mt-10 relative">
                  <SkeletonLoaderCategoryItems count={4} gridCols={2} />
                </div>
              ) : (
                <div className="dine-in-card grid grid-cols-2 gap-4 mx-4 mt-4">
                  <>
                    {categoryDataFetch?.data?.map(
                      (item: IcategoryResponseType) => {
                        return (
                          <div key={item?.id}>
                            <CategoryCard
                              id={item?.id}
                              imageUrl={item?.image?.path}
                              imageAlt={item?.name}
                              title={item?.name}
                              restaurantSlug={venuSlug}
                              zone={zone}
                              startTime={item?.start_time}
                              endTime={item?.end_time}
                              canOrder={item?.status}
                            />
                          </div>
                        );
                      }
                    )}
                  </>
                </div>
              )}
            </>
          }
         
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </AppLoader>
  );
};

export default React.memo(CategoryList);
