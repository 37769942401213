import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "antd/dist/antd.css";
import "../src/styles/main.scss";

import { routeData } from "./routes";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./pages/PageNotFound";
import MobileContainer from "./mobile-view-components/mobile-container";

function App(props: any) {
  AOS.init({
    // duration: 3000,
  });

  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route path="/"  element={<PageNotFound/>} />
        <Route  element={<MobileContainer />}>
        {routeData.map((item) => (
            <Route element={item?.element} path={item?.path} key={item?.id} />
          ))}
        </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        className={"z-99999"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default App;
