import React from "react";
import { ErrorMessage, FieldHookConfig, useField } from "formik";
interface IProps {
  label: string | number;
  placeholder?: string;
  type?: string;
  defaultValue?: string | number;
  readOnly?: boolean;
  hidden?: boolean;
  ref?: any;
  className?: string;
  onChange?: any;
}
const TextField = ({
  label,
  placeholder,
  type,
  readOnly,
  defaultValue,
  hidden,
  ref,
  className,
  onChange,
  ...otherProps
}: IProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(otherProps);
  return (
    <div className={`${className} mb-4`}>
      <label
        className={`font-AvenirLTLight text-xs font-semibold mb-2  block ${
          meta.error && meta.touched ? "text-black" : "text-black"
        }  `}
        htmlFor={field.name}
      >
        {label}
      </label>

      <div className="flex-1">
        <input
          className={`w-full appearance-none text-sm rounded py-2 block shadow-none placeholder:text-xs px-3 bg-white text-xs"  ${
            meta.touched && meta.error
              ? "ring-[#dc3545] focus:ring-1 focus-within:outline-none text-red-600 placeholder:text-red-600 ring-1"
              : "focus-within:ring-gray-300 focus-within:outline-none ring-1 ring-gray-300"
          } `}
          {...field}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          autoComplete="off"
          readOnly={readOnly}
        />
        <ErrorMessage
          component="div"
          name={field.name}
          className="hidden text-white mt-1 text-xs px-2"
        />
      </div>
    </div>
  );
};

export default React.memo(TextField);
