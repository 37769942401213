import {Dispatch} from 'redux'
import api from '.'
import {addToCart, get_Cart, removeFromCart} from '../Action/cart.action'

import {displayLoader} from '../Action/loader.action'
import {Service} from '../../mobile-view/network'
import {ADD_TO_CART, loaderAction} from '../const'
import { notify } from '../../Helpers/Notification'
export interface ICartPayload {
    menu_id: string
    quantity: string | number
    addons:{addon_Id:string,quantity:string}[]
}

export interface ICartParams {
    business: string
    service_zone: string
    cart_id?: string
    // '65a252a6-7fd9-4bb1-975f-a202944a8f58',
    order_from: string | number
}
export const handleAddToCart =
    (data: ICartPayload, params: ICartParams) => (dispatch: Dispatch) => {
        if (
            localStorage.getItem('cart_id') &&
            !['undefined', 'null'].includes(
                localStorage.getItem('cart_id') as any
            )
        ) {
            params.cart_id = localStorage.getItem('cart_id') as string
        }
        dispatch({type: loaderAction.GET_LOADER, payload: true})
        Service.post('/cart', data, {params})
            .then(res => {
                localStorage.setItem('cart_id', res?.data?.data?.cart_id)
                dispatch({
                    type: ADD_TO_CART,
                    payload: res.data.data,
                })
           notify('success',<><img className='rounded'  width={'20px'} src='/Images/rounded.tick.svg'/><span>Item added to cart</span></>)
             
            })
            .catch(err => console.log(err))
            .finally(() => {
                dispatch({type: loaderAction.GET_LOADER, payload: false})
            })
    }
export const handleUpdateCart =
    (data: any, navigate?: any, show?: boolean) => (dispatch: Dispatch) => {
        const key = 'updatable'
        const session_id = localStorage.getItem('session_id')

        if (session_id) {
            api.post(`/cart/update`, data, {
                headers: {
                    guestid: localStorage.getItem('session_id') || '',
                },
            })
                .then((response: any) => {
                    dispatch(displayLoader(false))
                    localStorage.setItem(
                        'session_id',
                        response.data.data.session_id
                    )
                    dispatch(addToCart(response.data.data))
                })
                .catch((err: any) => {
                    dispatch(displayLoader(false))
                })
        } else {
            api.post(`/cart/update`, data)
                .then((response: any) => {
                    dispatch(displayLoader(false))
                    localStorage.setItem(
                        'session_id',
                        response.data.data.session_id
                    )
                    dispatch(addToCart(response.data.data))
                })
                .catch((err: any) => {
                    dispatch(displayLoader(false))
                })
        }
    }
export const getCart = (zone?: string) => (dispatch: Dispatch) => {
    dispatch({type: loaderAction.GET_LOADER, payload: true})

    Service.get(`/cart`, {
        // headers: {
        //     guestid: localStorage.getItem("session_id") || "",
        // },
        params: {
            cart_id: localStorage.getItem('cart_id'),
            service_zone: zone,
        },
    })
        .then((response: any) => {
            dispatch({type: ADD_TO_CART, payload: response.data.data})
            // dispatch(displayLoader(false));
            // dispatch(get_Cart(response.data.data));
        })
        .catch((err: any) => {
            // dispatch(displayLoader(false));
        })
        .finally(() => {
            dispatch({type: loaderAction.GET_LOADER, payload: false})
        })
}
export const handleDelete =
    (cartItemId: string, productKey?: string) => (dispatch: Dispatch) => {
        dispatch({type: loaderAction.GET_LOADER, payload: true})
        Service.delete(`/cart/${cartItemId}`, {
            // headers: {
            //     guestid: localStorage.getItem("session_id") || "",
            // },
            params: {
                cart_id: localStorage.getItem('cart_id'),
            },
        })
            .then((response: any) => {
                dispatch({type: ADD_TO_CART, payload: response.data.data})

                // dispatch(displayLoader(false));
                // dispatch(removeFromCart(response.data.data?.data));
            })
            .catch((err: any) => {
                // dispatch(displayLoader(false));
            })
            .finally(() => {
                dispatch({type: loaderAction.GET_LOADER, payload: false})
            })
    }
    export const  handleDeleteAddonsFromCartItem  = ({item_id,cart_id,addon_id}:any) => (dispatch:any)=>{
        dispatch({type: loaderAction.GET_LOADER, payload: true})
            Service.delete('/cart/addon/'+addon_id,{params:{
                cart_id
            }}).then(({data})=>{
                dispatch({
                    type: ADD_TO_CART,
                    payload: data.data,
                })
            }).catch((er)=>{
                dispatch({
                    type: ADD_TO_CART,
                    payload: [],
                })
            }).finally(()=>{
                dispatch({type:loaderAction.GET_LOADER,payload:false})
            })
    }
