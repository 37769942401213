import React, { useEffect, useMemo, useState } from "react";
import { Service } from "../../mobile-view/network";
import { redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";

interface IProps {
  className?: string;
  imageUrl?: string;
  imageAlt?: string;
  isProdut?:boolean
}
type ImemoType = {banner:string|null,logo:string|null}
const BannerImage: React.FC<IProps> = ({ className,isProdut, imageUrl }) => {
  const { business_banner,business_logo } = useSelector(
    (state: StateType) => state.businessDetail
  );
  const  { banner,logo} = useMemo(():ImemoType=>{
    let banner = null ,logo = null
    if(business_banner){
        banner = business_banner.path
    }
    if(business_logo){
      logo = business_logo.path
    }
    return {
      banner,logo
    }
  },[business_banner,business_logo])

  return (
    <div className={`${className} relative mb-2 h-44 w-full `}>
      {banner || imageUrl ? (
        <img
          style={{
            objectPosition: "25% 30%",
          }}
          src={
            imageUrl ? imageUrl : banner ? banner : ""
          }
          alt={"Banner Image"}
          className="w-full h-full object-cover absolute  brightness-75"
        />
      ) : (
        <h1 className="text-sm text-center h-full flex justify-center items-center">
          Banner is being upload
        </h1>
      )}

      {
        !isProdut && <div className="clintLogo h-20 w-20  text-center absolute top-[40px] left-[40%] ">
        {
         <img
        src={logo || "/fav.ico"}
        alt="clientLogo"
        className="shadow-lg h-20 w-20 rounded-full "
        />
        }
        </div>
      }
    </div>
  );
};

export default React.memo(BannerImage);
