import axios from "axios";
import { BASE_API_URL } from "../../constant";

const API_BASE_URL_MOB = "https://qr-api.thecodeedev.com/api/v1";
//https://qr-api.thecodeedev.com

export const Service = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Authorization: "Bearer 104|r29IA2qRxhkLRmjlyp8ne42CSNGBh1I9c6581JhJ3414975d",
  },
});

export const ServiceCart = axios.create({
  baseURL: API_BASE_URL_MOB,
  headers: {
    // Authorization: "Bearer 8|xHFtdEX1dFZqG5xxXZgvjCc7YPQ3JKSRnD6rqiUS35ce5b44",
    "Content-Type": "application/json",
  },
});

// const getCategoryData = async (zone: any, deviceTime: any) => {
//   try {
//     const response = await axios.get(
//       `${process.env.API_BASE_URL_MOB }/category?zone=${zone}&device_time=${deviceTime}`
//     );

//     console.log("Response:", response.data);
//     return response.data;
//   } catch (error) {
//     // Handle error
//     console.error("Error fetching category data:", error);
//     throw new Error("Failed to fetch category data");
//   }
// };

// export default getCategoryData;
