import React from "react";
import clsx from "clsx";

interface IProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const ParagraphText = ({ className, children, onClick }: IProps) => {
  return (
    <>
      <div
        onClick={onClick}
        className={clsx(className, "text-base font-normal font-AvenirLTLight")}
      >
        {children}
      </div>
    </>
  );
};

export default ParagraphText;
