import categoryReducer, { CategoryReducer } from "./category.reducer";
import { combineReducers } from "redux";
import menuReducer, { MenuReducer } from "./menu.reducer";
import detailProductReducer, {
  DetailProductReducer,
} from "./detailproduct.reducer";
import cartReducer, { CartReducar } from "./cart.reducer";
import orderReducer, { OrderReducer } from "./order.reducer";
import resturantProfileReducer, {
  ResturantProfileReducer,
} from "./resturantProfile.reducer";
import receiptReducer, { ReceiptReducer } from "./receipt.reducer";

import mobileAddToTableNumberReducer, {
  mobileAddToTableNumber,
} from "./mobile/table-number.reducer";
import mobileProductCardReducer, {
  MobileProductCard,
} from "./mobile/cardProduct.reducer";

import {venuServiceReducer,IvenuServiceReducer} from './mobile/venuService.reducer'
import BusinessReducer from "./Business.reducer";
export interface StateType {
  category: CategoryReducer;
  menu: MenuReducer;
  detailProduct: DetailProductReducer;
  cart: CartReducar;
  profile: ResturantProfileReducer;
  order: OrderReducer;
  receipt: ReceiptReducer;
  addTableNumberMobile: mobileAddToTableNumber;
  CardProductMobile: MobileProductCard;
  venuService:IvenuServiceReducer,
  businessDetail:any
}
const rootReducer = combineReducers({
category: categoryReducer,
menu: menuReducer,
detailProduct: detailProductReducer,
cart: cartReducer,
profile: resturantProfileReducer,
order: orderReducer,
receipt: receiptReducer,
addTableNumberMobile: mobileAddToTableNumberReducer,
CardProductMobile: mobileProductCardReducer,
venuService: venuServiceReducer,
businessDetail:BusinessReducer
});
export default rootReducer;
