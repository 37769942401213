import React from "react";

interface IProps {
  className?: string;
}

const Footer = ({ className }: IProps) => {
  return (
    <div
      className={`${className} bg-white   w-full mx-auto sm:w-[480px] md:w-[480px] xl:w-[480px] lg:w-[480px] 2xl:w-[480px] mt-3`}
    >
      <div className="pt-2 pb-6 flex justify-between max-w-[70%] mx-auto gap-[8%]">
        <hr className="h-px w-full  my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="mt-4">
          <img
            src="/MobileViewImages/logoTextBlack.svg"
            alt=""
            className="h-full  w-80 object-contain"
          />
        </div>
        <hr className="h-px w-full my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      </div>
    </div>
  );
};

export default Footer;
