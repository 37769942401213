import { Dispatch } from "redux";
import api from ".";
import { emptyCart } from "../Action/cart.action";
import { displayLoader } from "../Action/loader.action";
import { createOrder } from "../Action/order.action";
import { Service } from "../../mobile-view/network";
import { loaderAction, orderAction } from "../const";
export interface OrderBodyType {
  resturant: number;
  subTotal: number;
  total: number;
  table: number;
  name: string;
  kitchen_note: string;
  phone: any;
}

export const ApiCreateOrder =
  (
    data: OrderBodyType,
    props: { currentVenue: string | undefined; zone: any; navigate: any }
  ) =>
  (dispatch: Dispatch) => {
    const { currentVenue, zone, navigate } = props;

    api
      .post(`/order/create`, data, {
        headers: {
          guestid: localStorage.getItem("session_id") || "",
        },
      })
      .then((response) => {
        dispatch(createOrder(response.data));
        dispatch(emptyCart());
        currentVenue &&
          navigate(`/menu/${currentVenue}/${zone}/greeting`, {
            replace: true,
          });
        sessionStorage.removeItem("tablenum");
      })
      .catch((err) => {
        dispatch(displayLoader(false));
      });
  };
export const getSingleOrder = (id: string) => (dispatch: any) => {
  dispatch({ type: loaderAction.GET_LOADER, payload: true });
  Service.get(`/orders/${id}/detail`, {
    params: {
      with_items: true,
      with_images: true,
      // with_addons:true
    },
  })
    .then((res) => {
      dispatch({
        type: orderAction.GET_ORDER,
        payload: res?.data?.data || null,
      });
      dispatch({ type: loaderAction.GET_LOADER, payload: true });
    })
    .catch((err) => dispatch({ type: orderAction.GET_ORDER, payload: null }))
    .finally(() => dispatch({ type: loaderAction.GET_LOADER, payload: false }));
};
