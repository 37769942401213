import React, { useContext } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { FaHotel } from "react-icons/fa";
import { GiBoxUnpacking } from "react-icons/gi";
import { MdDeliveryDining } from "react-icons/md";

import { Drawer, Footer, ParagraphText } from "../../../mobile-view-components";
import { drawerData } from "./static-data";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";


import { useDispatch, useSelector } from "react-redux";
import { getVenueService } from "../../../store/api/venuService";

import { StateType } from "../../../store/Reducer/index.reducer";
import { MobileContainerContext } from "../../../mobile-view-components/mobile-container";
import _ from "lodash";
import { AppLoader } from "../../../components/Loader/app.loader";
import { HomeFilled } from "@ant-design/icons";
interface IProps {}

const Home: React.FC<IProps> = ({}) => {
  const navigate = useNavigate();
  const { venuSlug } = useParams();
  const {
    handleModalClick,
    setHandlers,
    isProfileLoading,
    setSocialModalOpen,
    setFeedBackModalOpen,
    setWifiModalOpen,
  } = useContext(MobileContainerContext);
  const { services, loading } = useSelector(
    (state: StateType) => state.venuService
  );
  const dispatch: any = useDispatch();

  const { tableNumber, businessDetail } = useSelector((state: any) => ({
    tableNumber: state?.addTableNumberMobile.tableNumber,
    businessDetail: state?.businessDetail,
  }));
  
  

  const [showDrawer, setShowDrawer] = React.useState(false);

  


  React.useEffect(() => {
    if (venuSlug) dispatch(getVenueService(venuSlug));
  }, [venuSlug]);

  React.useEffect(() => {
    // Remove datas from local storage
    localStorage.removeItem("guestIdMob");
    localStorage.removeItem("userOrderPlacedDataMob");
    localStorage.removeItem("orderPlacedByUserMob");
  }, []);

  const redirectToCategoryList = ({ slug, id }: handleNavigationType) => {
    navigate(`/category/${venuSlug}?zone=${slug}`, {
      state: {
        zoneId: id,
      },
    });
  };
  type handleNavigationType = {
    slug: string;
    id: string | number;
  };

  const handleNavigation = ({ slug, id }: handleNavigationType) => {
    if (!tableNumber) {
      handleModalClick();
      setHandlers((prev: any) => ({
        ...prev,
        redirect: () => redirectToCategoryList({ slug, id }),
      }));
    } else if (tableNumber) {
      redirectToCategoryList({ slug, id });
    }
  };

  const MenuMap = ({ slug, zone, id, icon }: any) => {
    return (
      <div
        onClick={() => {
          handleNavigation({ slug, id });
        }}
        className="menuBar  bg-primary-yellow rounded-lg text-xl text-white font-bold cursor-pointer text-center p-2 transition-all duration-300 transform hover:scale-105 font-AvenirLTlight shadow-md "
      >
        {zone}

        {icon}
      </div>
    );
  };
  const Wifi =(
<div onClick={()=>setWifiModalOpen(true)} className="Feedback bg-gray-400 rounded-lg text-xl text-white font-bold cursor-pointer text-center p-2 transition-all duration-300 transform hover:scale-105 font-AvenirLTlight shadow-md">
              Wi-Fi
    </div>
  );

  const SocialLink = (
    <div
      onClick={() => setSocialModalOpen(true)}
      className="socilaLink bg-gray-400 rounded-lg text-xl text-white font-bold cursor-pointer text-center p-2 transition-all duration-300 transform hover:scale-105 font-AvenirLTlight shadow-md "
    >
      Social Link
    </div>
  );


  return (
    <AppLoader isLoading={loading || isProfileLoading}>
      <div className="relative flex flex-col  justify-between flex-1">
        <div className="container bg-white px-10  py-10 ">
          <div className="client flex justify-end">
            <div className="clintLogo h-20 w-20   rounded-xl text-center absolute top-[35px] left-[35px] ">
              {businessDetail?.business_logo ? (
                <img
                  src={
                    businessDetail.business_logo
                      ? businessDetail.business_logo.path
                      : ""
                  }
                  alt="clientLogo"
                  className="shadow-lg h-20 w-20 rounded-full object-cover "
                />
              ):<img src="fav.ico" />}
            </div>
            <div className="status">
              <div className=" bg-primary-yellow py-2  px-5 rounded-md flex items-center text-center hover:bg-gray-300 cursor-pointer  font-bold  ">
                <p className="mx-1 my-0">Open</p> <FaRegClock />
              </div>
              <div className="message flex items-center justify-end  gap-2 my-3 text-3xl">
                {" "}
                <SocialIcon style={{width:'30px',height:"30px"}}  
                url={'https://whatsapp.com'} 
                href={`https://wa.me/${encodeURIComponent(+977 + businessDetail.phone)}?text=${encodeURIComponent('Hello there !')}`}
                target="_blank"
                />
              
                <HomeFilled  className="!text-primary-yellow"
                onClick={() => {
                  setShowDrawer(true);
                }} />
              </div>
            </div>
          </div>
          <div className="welcome item-center  my-8">
            <h1 className=" text-3xl text-center font-AvenirLTMedium font-bold">
              WELCOME{" "}
             
            </h1>
            <h1 className=" text-center capitalize font-AvenirLTlight font-bold   text-s ">
              {businessDetail?.name}
            </h1>
          </div>
          <div className="options flex justify-center gap-4 p-2 py-8 ">
            {[
              {
                label: "On Site",
                icon: (
                  <FaHotel size={25} className=" text-primary-yellow  transition duration-150 group-hover:scale-[1.2] cursor-pointer" />
                ),
              },
              {
                label: "Take Away",
                icon: (
                  <GiBoxUnpacking  size={25} className=" text-primary-yellow  transition duration-150 group-hover:scale-[1.2] cursor-pointer" />
                ),
              },
              {
                label: "Delivery",
                icon: (
                  <MdDeliveryDining   size={25} className=" text-primary-yellow  transition duration-150 group-hover:scale-[1.2] cursor-pointer" />
                ),
              },
            ].slice(0,1).map(({ label, icon }, index) => (
              <div
                key={index}
                className="packing cursor-pointer  group  [&:not(:first-child)]:border-x-2  last:border-none  border-gray-200 flex flex-col items-center px-5 py-4"
              >
                {icon}
                <p className=" font-semibold my-2 ont-AvenirLTlight">{label}</p>
              </div>
            ))}
          </div>

          <div className="menu flex  gap-3 flex-col">
            {services?.map((item: any, index: number) => (
              <MenuMap
                och
                key={index}
                slug={item.slug}
                zone={item.zone}
                icon={""}
                id={item.id}
              />
            ))}

            {Wifi}
            
            {SocialLink}

            <div onClick={()=>setFeedBackModalOpen(true)} className="Feedback bg-gray-400 rounded-lg text-xl text-white font-bold cursor-pointer text-center p-2 transition-all duration-300 transform hover:scale-105 font-AvenirLTlight shadow-md">
              Feedback
            </div>
          </div>
        </div>
        

        {showDrawer && (
          
          <Drawer setShowDrawer={setShowDrawer} showDrawer={showDrawer} >
            <img src="/Images/logo.svg" alt="" className="object-cover mb-10" />
            <div className="mt-14 mb-5 mx-5">
            
              {drawerData.map((item) => (
                <div className="my-10" key={item?.id}>
                  <Link
                    className="text-black text-lg font-AvenirLTLight"
                    to={{pathname:`${item?.path}`,search:`venue=${venuSlug}`}} target={item.target}
                  
                  >
                    {item?.title}
                  </Link>
                </div>
              ))}
        
              
              <ParagraphText className="mb-2 mt-20 text-sm text-gray-500">
                <p>{businessDetail?.address}</p>
               
                <p className="leading-[0px]">{businessDetail?.country?.name}</p>
              </ParagraphText>
              <ParagraphText className="mt-5 text-sm text-gray-500">
                <p>+977 {businessDetail?.phone}</p>
                <p className="leading-[0px]">{businessDetail?.business_email}</p>
              </ParagraphText>

              {/* Social media icons */}
              <div className="flex space-x-4 mt-[10%]">
              { businessDetail?.socials
             ?.map(({url}:any,index:number) => (
            
                <SocialIcon style={{width:'30px',height:"30px"}}  
                url={url} 
               href={url} 
               target="_blank"
                />
  
            ))}
            
                
              </div>
            </div>
          </Drawer>
        )}

        {/* Footer section */}
        <Footer />
      </div>
    </AppLoader>
  );
};

export default React.memo(Home);

