import Skeleton from "react-loading-skeleton";

const SkeletonLoaderCategoryItems = ({ count = 1, gridCols = 1 }) => {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <div key={i} className="">
          <div className="flex flex-col  gap-4 ">
            <Skeleton width="100%" height={110} />
            <Skeleton width={150} height={25} />
          </div>
        </div>
      );
    }
    return skeletons;
  };

  return (
    <div className={`grid grid-cols-${gridCols} w-full gap-8 px-4 `}>
      {renderSkeletons()}
    </div>
  );
};

export default SkeletonLoaderCategoryItems;
