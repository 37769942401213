

const initial = {}


const BusinessReducer = (state=initial,action:any) =>{
  switch (action.type){
   
    case 'FETCH_PROFILE':
      state = {
        ...state,
       ...action.payload
      }
      
  }
  return state;

}

export default BusinessReducer