import React from "react";
import { Service } from "../network";

const useFetch = (url: string) => {
  const [data, setData] = React.useState<any>();
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const getData = React.useCallback(async () => {
    setIsLoading(true);
    setData([]);
    setErrorMsg("");
    try {
      const { data } = await Service.get(url);
      setIsLoading(false);
      setData(data);
    } catch (err: any) {
      setIsLoading(false);
      setErrorMsg(err?.response?.data?.message);
    }
  }, [url]);

  React.useEffect(() => {
   if(url)  getData();
  }, [url]);

  return { data, isLoading, errorMsg };
};

export default useFetch;
