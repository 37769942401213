import React from "react";
import { useNavigate } from "react-router-dom";
import TitleText from "../common/TitleText";
import { categoryImagesBaseUrl } from "../_constants";
import { device_time, isCategoryOrderAvailable } from "../_helpers";
import ParagraphText from "../common/ParagraphText";

const CategoryCard = (props: any) => {
  const navigate = useNavigate();

  const redirectToMenuList = () => {
    navigate(
      `/${props?.restaurantSlug}/${props?.zone}/menu-list/${props?.id}`,
      {
        state: {
          category_name: props.title,
        },
      }
    );
  };

  const isCategoryAvailable = isCategoryOrderAvailable(
    device_time,
    props?.startTime,
    props?.endTime
  );

  return (
    <div>
      {props?.canOrder && isCategoryAvailable ? (
        <>
          <div
            className="w-full "
            aria-label="Menu category"
            onClick={() => {
              redirectToMenuList();
            }}
          >
            <div className="relative h-28 w-full cursor-pointer">
              <img
                src={
                  props?.imageUrl
                    ? `${props?.imageUrl}`
                    : "/Images/category.png"
                }
                alt={props?.name}
                className="absolute h-full w-full object-cover rounded-md "
              />
            </div>
            <TitleText className="text-center text-base leading-tight mt-3 capitalize cursor-pointer">
              {props?.title}
            </TitleText>
          </div>
        </>
      ) : (
        <>
          <div
            className="w-full "
            aria-label="Menu category"
            onClick={() => {
              redirectToMenuList();
            }}
          >
            <div className="cursor-pointer background-image-category relative z-[1] mb-3 h-28 w-full overflow-hidden rounded bg-surface-subtle bg-cover p-4 pt-[37%]">
              <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-black bg-opacity-60">
                <ParagraphText className="px-5 text-center text-white my-label-md">
                  Ordering not available
                </ParagraphText>
              </div>
            </div>
            <TitleText className="text-base text-center leading-tight mt-4 capitalize cursor-pointer">
              {props?.title}
            </TitleText>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryCard;
