import Skeleton from "react-loading-skeleton";

const SkeletonLoaderCartItems = ({ count = 2, gridCols = 1 }) => {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <div key={i} className="flex justify-between">
          <div className="flex flex-col gap-2 ">
            <Skeleton width={250} height={30} />
            <Skeleton width={120} height={20} />
          </div>
          <Skeleton width={40} height={40} circle />
        </div>
      );
    }
    return skeletons;
  };

  return (
    <div className={`grid grid-cols-${gridCols} w-full gap-12 px-2 `}>
      {renderSkeletons()}
    </div>
  );
};

export default SkeletonLoaderCartItems;
