import Skeleton from "react-loading-skeleton";

const SkeletonLoaderMenuProducts = ({ count = 2, gridCols = 1 }) => {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <div key={i} className="flex justify-between">
          <div className="flex flex-col gap-2 my-auto ">
            <Skeleton width={120} height={15} />

            <Skeleton width={160} height={30} />
            <Skeleton width={120} height={15} />
          </div>
          <Skeleton width={130} height={130} borderRadius={20} />
        </div>
      );
    }
    return skeletons;
  };

  return (
    <div className={`grid grid-cols-${gridCols} w-full gap-12 mx-4 `}>
      {renderSkeletons()}
    </div>
  );
};

export default SkeletonLoaderMenuProducts;
