export interface MobileProduct {
  id: number;
  name: string;
  quantity: number;
}

export interface MobileProductCard {
  mobileCardProducts: MobileProduct[];
}

const initialData: MobileProductCard = {
  mobileCardProducts: [],
};
const mobileProductCardReducer = (
  state = initialData,
  action: any
): MobileProductCard => {
  switch (action.type) {
    case "ADD_PRODUCT_TO_CARD":
      const { id, name } = action.payload;
      const existingProductIndex = state.mobileCardProducts.findIndex(
        (product) => product.id === id
      );

      if (existingProductIndex !== -1) {
        // If product already exists, update its quantity
        const updatedProducts = [...state.mobileCardProducts];
        updatedProducts[existingProductIndex].quantity++;
        return {
          ...state,
          mobileCardProducts: updatedProducts,
        };
      } else {
        // If product doesn't exist, add it to the card
        const newProduct: MobileProduct = {
          id,
          name,
          quantity: 1, // Initial quantity for a new product
        };
        return {
          ...state,
          mobileCardProducts: [...state.mobileCardProducts, newProduct],
        };
      }
    case "UPDATE_PRODUCT_QUANTITY":
      const { productId, newQuantity } = action.payload;
      const updatedProducts = state.mobileCardProducts.map((product) =>
        product.id === productId
          ? { ...product, quantity: newQuantity }
          : product
      );
      return {
        ...state,
        mobileCardProducts: updatedProducts,
      };
    case "DELETE_PRODUCT_FROM_CARD":
      const { deletedProductId } = action.payload;
      const updatedProductsAfterDeletion = state.mobileCardProducts.filter(
        (product) => product.id !== deletedProductId
      );
      return {
        ...state,
        mobileCardProducts: updatedProductsAfterDeletion,
      };
    default:
      return state;
  }
};

export default mobileProductCardReducer;
